<template>
  <v-container>
    <v-carousel
      cycle
      height="240"
      class="pb-3"
      interval="10000"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(promotion) in promotions"
        :key="promotion._id"
        class="fill-height bottom-gradient"
        @click="$router.push({
            name : 'Display Promotion Detail',
            params: {
                id: promotion._id
            }
        })"
      >
        <v-img
          :src="
            promotion.dishs[0].image
              ? promotion.dishs[0].image
              : require('@/assets/placeholder.png')
          "
          style="
            position: absolute;
            width: 100%;
            height: 100%;
            filter: blur(6px);
            -webkit-filter: blur(6px);
            -moz-filter: blur(6px);
          "
        >
        </v-img>
        <v-overlay :absolute="true" value="true">
          <h1 class="text-center">{{ promotion.name }}</h1>
          <p v-if="promotion.coupon" class="mt-2 text-center">
            Applicable Coupon :
            <v-chip label small class="black--text" color="white"
              >#{{ promotion.coupon.code }}</v-chip
            >
          </p>
          <p class="text-truncate" style="max-width: 70vw">
            This promotion contains :
            {{
              truncateList(promotion.dishs, 4)
                .dishs.map((dish) => dish.title)
                .join(", ")
            }}
            <span v-if="truncateList(promotion.dishs, 4).truncated">
              and {{ truncateList(promotion.dishs, 4).truncated }} more dishes
            </span>
          </p>
        </v-overlay>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  name: "PromotionShowcaseList",
  data: function () {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  props: {
    promotions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    truncateList: function (dishs, limit) {
      var newList = dishs.filter((e, index) => {
        return index < limit;
      });
      return {
        dishs: newList,
        truncated: dishs.length - newList.length,
      };
    },
  },
};
</script>

<style scoped>
.img-blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
}
</style>