<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-container class="dark">
                    <h1 class="text-center display-3">Login in</h1>
          <v-divider class="py-2"></v-divider>
          <v-form ref="form"  @submit.prevent="">
            <v-text-field
              prepend-icon="mdi-email"
              :error="invalidCred"
              :messages="(invalidCred)? ['Invalid Credentials'] : []"
              v-model="email.content"
              label="Email"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-form-textbox-password"
              :error="invalidCred"
              :messages="(invalidCred)? ['Invalid Credentials'] : []"
              v-model="password.content"
              label="Password"
              :type="(password.show)? 'text' : 'password'"
              required
            >
              <v-icon slot="append" @click="password.show = !password.show">{{(!password.show)? "mdi-eye" : "mdi-eye-off"}}</v-icon>
            </v-text-field>

            <div class="d-flex justify-content-between">
              <router-link to="/signup">Don't have an account ? Create one here </router-link>
              <router-link to="/user/forgetPassword">Forget Password </router-link>
            </div>

            <v-btn block color="primary" class="mt-2 mr-4" @click="login" >
              <v-icon class="mr-2">mdi-login</v-icon>
              Login
            </v-btn>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template> 

<script>
import axios from "axios"
export default {
  name: "Login",
  data() {
    return {
      email: {
          content : "",
      },
      password: {
          content: "",
          show: false,
      },
      invalidCred: false,
    };
  },
  methods: {
    login: async function (e) {
      e.preventDefault();
      this.invalidCred = false;
        var selfRouter = this.$router; 
        var selfStore = this.$store; 
        await this.$store.dispatch("AuthModule/login", {email: this.email.content, password: this.password.content})
    },
  },
};
</script>