
import AdminListUser from '@/components/worker/Tools/ListUser/ListUser.vue'
import AdminTools from '@/components/admin/Tools/Tools.vue'
import DishCreate from '@/components/admin/Tools/Dish/CreateDish.vue'
import DishEdit from '@/components/admin/Tools/Dish/EditDish.vue'
import ListCoupon from '@/components/admin/Tools/Coupon/ListCoupon.vue'
import CreateCoupon from '@/components/admin/Tools/Coupon/CreateCoupon.vue'
import ListPromotion from '@/components/admin/Tools/Promotion/ListPromotion.vue'
import CreatePromotion from '@/components/admin/Tools/Promotion/CreatePromotion.vue'
import EditPromotion from '@/components/admin/Tools/Promotion/EditPromotion.vue'
export const AdminRouter = [
      {
        path: '/admin/listUser',
        name: 'List Profile',
        component: AdminListUser,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/coupon',
        name: "List Coupon",
        component: ListCoupon,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/coupon/create',
        name: "Create Coupon",
        component: CreateCoupon,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/promotion',
        name: "List Promotion",
        component: ListPromotion,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/promotion/create',
        name: "Create Promotion",
        component: CreatePromotion,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/promotion/edit/:id',
        name: "Edit Promotion",
        component: EditPromotion,
        meta: {requireLogin: true, userType: "admin"}
      },

      {
        path: '/admin/tools',
        name: 'Admin Tools',
        component: AdminTools,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/dish/create',
        name: 'Dish Create',
        component: DishCreate,
        meta: {requireLogin: true, userType: "admin"}
      },
      {
        path: '/dish/edit/:id',
        name: 'Dish Edit',
        component: DishEdit,
        meta: {requireLogin: true, userType: "admin"}
      }
]

