<template>
  <div
    class="mx-auto mt-10"
    :style="$store.state.MiscModule.isMobile ? '' : 'max-width: 70%;'"
  >
    <div class="d-flex justify-content-center">
      <v-btn class="mx-auto" large @click="turnOnCamera()">Scan QR Code</v-btn>
    </div>
    <v-container v-if="camera == 'auto'" style="max-width: 300px">
      <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
      </qrcode-stream>
    </v-container>
    <div class="" v-if="order">
      <Receipt class="w-100" :order="order">
        <code v-if="order">#{{ order._id }}</code>
      </Receipt>
      <div class="d-flex justify-content-center">
        <v-btn
          class="mx-auto"
          color="success"
          large
          v-if="order.isCompleted && order.isPaid && !order.isCollected"
          @click="setOrderAsCollected()"
          >Order Collected</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import beepData from "@/assets/beep.wav";
import Receipt from "../payment/Receipt";
import Order from "../../service/order.service";
import Worker from "../../service/worker.service";
export default {
  name: "Scan Receipt",
  components: { QrcodeStream, Receipt },
  data() {
    return {
      isValid: undefined,
      camera: "off",
      result: null,
      beep: null,

      order: null,
    };
  },
  methods: {
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },
    playBeep: function () {
      this.beep.play();
    },
    loadOrderData: async function () {
      const order = new Order();
      const result = await order.getOrder(this.result);
      this.order = result.content;
    },
    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.camera = "off";

      this.playBeep();
      if (
        /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(
          this.result
        )
      ) {
        await this.loadOrderData();
      } else {
        alert("unknown qr code");
      }
    },
    setOrderAsCollected: async function () {
      const worker = new Worker();
      
      try {
        if (
          !confirm(
            "Are you sure you want to make this order to collected? you cant change it back once you change it"
          )
        ) {
          return;
        }
        const result = await worker.setOrderAsCollected(this.result);
        this.$store.dispatch("ModalModule/success", {
          title: "Order has been collected",
          content: "The order has been successfully collected",
          buttonContent: "Ok",
        });
      } catch (error) {
        console.log(error)
        this.$store.dispatch("ModalModule/error", {
          title: "Order collect setting failed",
          content: "Could not set the order to collected  ",
          buttonContent: "Ok",
        });
      }
    },
    turnOnCamera: async function () {
      this.order = null;
      this.camera = "auto";
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
  mounted: function () {
    this.beep = new Audio(beepData);
  },
};
</script>