<template>
  <v-card @click="$router.push({
    name: 'View Dish',
    params: {
      id: item._id
    }
  })">
    <v-img
      :src="(item.image)? item.image : '../../../../assets/placeholder.png'"
      lazy-src="../../../../assets/placeholder.png"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
      :alt="item.title"
      class="white--text align-end imageSize"
    >

      <v-card-title elevation>{{ item.title }}</v-card-title>
    </v-img>
    <div class="card-body">
      <h4 class="card-title"></h4>
      <h3 class="card-text">{{item.price}} MYR</h3>
    </div>
  </v-card>
</template>


<script>
export default {
  name: "DishItem",
  props: ["item"],
};
</script>

<style scoped>
  a{
    text-decoration: none;
  }
  .imageSize {
    max-height: 129px;
    min-height: 129px;
  }
</style>