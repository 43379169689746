<template>
  <v-container>
    <h1>Batch</h1>
    <v-container>
      <v-btn class="" color="primary" elevation="2" @click="loadModal()"
        >Create</v-btn
      >
    </v-container>
    <v-list three-line>
      <template v-for="(batch, index) in batchs">
        <v-list-item :key="batch._id">
          <v-list-item-content>
            <div class="d-flex justify-content-between">
              <p>
                {{ batch.name }} {{ hourFormat(batch.hour) }}
                {{ batch.hidden ? "(hidden)" : "" }}
              </p>
              <div class="">
                <v-btn
                  class="mr-2"
                  color="warning"
                  @click="loadModal(batch._id)"
                  >Edit</v-btn
                >
                <v-btn color="error" @click="removeBatch(batch._id)">{{
                  batch.hidden ? "Unhide" : "Delete"
                }}</v-btn>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          class="m-0"
          :key="index"
          v-if="index < batchs.length - 1"
        ></v-divider>
      </template>
    </v-list>
    <BatchesModal
      @loadBatch="loadBatch()"
      :batchModal="batchModal"
      :update="update"
    />
  </v-container>
</template>

<script>
import BatchesModal from "./partial/BatchesModal";
import Batch from "../../../../service/batch.service";
export default {
  name: "Batches",
  data: function () {
    return {
      batchModal: {
        state: false,
        id: null,
        name: {
          content: "",
          error: [],
        },
        hour: {
          content: 7,
          error: [],
        },
      },
      update: false,
      batchs: [],
    };
  },
  components: { BatchesModal },
  methods: {
    loadBatch: async function () {
      const batch = new Batch();
      try {
        const result = await batch.getAllBatches();
        this.batchs = result.content;
      } catch (error) {
        console.log(error);
      }
    },
    loadModal: async function (id = null) {
      if (id == null) {
        this.update = false;
        this.batchModal.batch = null;
        this.batchModal.state = true;
        this.batchModal.name.content = "";
        this.batchModal.hour.content = 7;
        return;
      }
      const batch = this.batchs.find((b) => b._id == id);
      if (!batch) {
        this.$store.dispatch("ModalModule/error", {
          title: "Error",
          content: `Could not find the batch`,
          buttonContent: "OK",
        });
      }
      this.update = true;
      this.batchModal.name.content = batch.name;
      this.batchModal.hour.content = batch.hour;
      this.batchModal.batch = batch;
      this.batchModal.state = true;
    },
    removeBatch: async function (id) {
      if (!confirm("are you sure you want to delete the batch ?")) {
          return;
      }
      const batch = new Batch();
      try {
        const { name, hour } = this.batchModal;
        const result = await batch.removeBatch(id);
        this.$store.dispatch("ModalModule/success", {
          title: "Batch deleted successfully",
          content: `${result.content}`,
          buttonContent: "OK",
        });
        this.loadBatch();
        this.$emit("loadBatch", "");
      } catch (error) {
        console.log(error);
      }
    },
    hourFormat: function (hour) {
      if (hour < 12) {
        return `${hour}:00am`;
      } else if (hour == 12) {
        return `${hour}:00pm`;
      }
      return `${hour - 12}:00pm`;
    },
  },
  mounted: async function () {
    await this.loadBatch();
  },
};
</script>

<style>
</style>