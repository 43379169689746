import Home from '@/components/common/Home.vue'
import Error from '@/components/common/Error.vue'
import Error404 from '@/components/common/404.vue'
import ViewDish from '@/components/dishes/ViewDish.vue'
import Dish from '@/components/dishes/Dish.vue'
import Signup from '@/components/user/Signup.vue'
import Login from '@/components/user/Login.vue'
import PromotionShowcaseDetail from '@/components/partial/promotionShowcase/PromotionShowcaseDetail.vue'

export const CommonRouter = [  
  { path: "*", component: Error404 },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'Sign up',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {requireNotLogin: true}
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  {
    path: '/dish/:id',
    name: 'View Dish',
    component: ViewDish
  },
  {
    path: '/dish',
    name: 'Dish',
    component: Dish
  },
  {
    path: '/public/promotion/:id',
    name: "Display Promotion Detail",
    component: PromotionShowcaseDetail
  }
]