var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:(_vm.$store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'},[_c('v-card',{staticClass:"mx-auto my-3 p-3"},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'List Promotion',
        })}}},[_vm._v("Back")]),_c('h1',[_vm._v("Promotion")]),_c('v-text-field',{attrs:{"name":"Promotion Name","label":"Promotion Name","hint":"Please enter the name of the promotion","error-messages":_vm.promotionData.name.error},model:{value:(_vm.promotionData.name.content),callback:function ($$v) {_vm.$set(_vm.promotionData.name, "content", $$v)},expression:"promotionData.name.content"}}),_c('v-select',{attrs:{"items":_vm.coupons,"label":"Coupon","item-value":"_id","item-text":"_id","hint":"Coupon that user can use to enjoy more discounts","persistent-hint":"","clearable":"","error-messages":_vm.promotionData.coupon.error},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name == item.code ? "" : item.name)+" #"+_vm._s(item.code)+" "),_c('span',{staticClass:"ml-3"},[_vm._v(" - "),_c('span',[_vm._v(_vm._s(item.type == "amount" ? "Deduct" : "Discount"))]),_vm._v(" "+_vm._s(item.value)+" "),_c('span',[_vm._v(_vm._s(item.type == "amount" ? "MYR" : "%"))])])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name == item.code ? "" : item.name)+" #"+_vm._s(item.code)+" "),_c('span',{staticClass:"ml-3"},[_vm._v(" - "),_c('span',[_vm._v(_vm._s(item.type == "amount" ? "Deduct" : "Discount"))]),_vm._v(" "+_vm._s(item.value)+" "),_c('span',[_vm._v(_vm._s(item.type == "amount" ? "MYR" : "%"))])])]}}]),model:{value:(_vm.promotionData.coupon.content),callback:function ($$v) {_vm.$set(_vm.promotionData.coupon, "content", $$v)},expression:"promotionData.coupon.content"}}),_c('v-select',{attrs:{"items":_vm.dishs,"item-value":"_id","item-text":"_id","label":"Dish","hint":"List of dish related to this promotion","clearable":"","multiple":"","error-messages":_vm.promotionData.dishs.error},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center "},[_c('div',{},[_c('v-img',{attrs:{"max-width":"64","max-height":"64","width":"64","height":"64","src":item.image,"lazy-src":require("@/assets/placeholder.png")}})],1),_c('div',{staticClass:"p-3"},[_c('p',[_vm._v(_vm._s(item.title)+" - "+_vm._s(item.price.toFixed(2))+" MYR")]),_c('p',[_vm._v("Available Addon : "+_vm._s(_vm.addonDisplay(item).join(",")))])])])]}},{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(item.title))])]}}]),model:{value:(_vm.promotionData.dishs.content),callback:function ($$v) {_vm.$set(_vm.promotionData.dishs, "content", $$v)},expression:"promotionData.dishs.content"}}),_c('p',[_vm._v("Should the promotion be immediately available ?")]),_c('v-checkbox',{attrs:{"label":"Make it hidden"},model:{value:(_vm.promotionData.hidden.content),callback:function ($$v) {_vm.$set(_vm.promotionData.hidden, "content", $$v)},expression:"promotionData.hidden.content"}}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updatePromotion()}}},[_vm._v("Update Promotion")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }