export default class InputValidator {
    static mergeError(input, errorContent) {
        this.clearError(input);
        for (const fieldName in errorContent) {
            if (Object.hasOwnProperty.call(errorContent, fieldName)) {
                const errors = errorContent[fieldName];
                console.log({e:input[fieldName]});
                if (input[fieldName] != undefined) {
                    if (input[fieldName].error != undefined) {
                      input[fieldName].error = errors.message;
                    }
                }
            }
          }
    }

    static clearError(input) {
        for (const inputName in input) {
            if (Object.hasOwnProperty.call(input, inputName)) {
                const inputData = input[inputName];
                if (inputData != null) {
                    if (inputData.error != undefined) {
                        inputData.error = [];
                    }
                }
                
            }
        }
    }
}