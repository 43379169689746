import ListUser from '@/components/worker/Tools/ListUser/ListUser.vue'
import DisplayUserProfile from '@/components/worker/Tools/DisplayUserProfile/DisplayUserProfile.vue'
import workerTools from '@/components/worker/Tools/Tools.vue'
import WorkerOrder from '@/components/worker/order/workerOrder.vue'
import ViewReceipt from "@/components/payment/ViewReceipt.vue"
import ScanReceipt from "@/components/worker/ScanReceipt.vue"
export const WorkerRouter = [
      {
        path: '/worker/listUser',
        name: 'List User',
        component: ListUser,
        meta: {requireLogin: true, userType: "worker"}
      },
      {
        path: '/worker/user/:id',
        name: 'Display user profile',
        component: DisplayUserProfile,
        meta: {requireLogin: true, }
      },
      {
        path: '/worker/tools',
        name: 'Worker Tools',
        component: workerTools,
        meta: {requireLogin: true, userType: "worker"}
      },
      {
        path: "/worker/order",
        name: "Worker Order", 
        component: WorkerOrder,
        meta: {requireLogin: true, userType: ["admin","worker"]}
      },
      {
        path: "/worker/receipt/scan",
        name: "Receipt Scan", 
        component: ScanReceipt,
        meta: {requireLogin: true, userType: ["admin","worker"]}
      }

]