<template>
  <v-app>
    <Navigation></Navigation>
      <Modal :isDisabled="true" title="test" bgColor="warning" content="pizza"> </Modal>
    
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Cookie from "./utility/cookie"
import Navigation from "./components/partial/navigation/Navigation";
import Modal from './components/partial/modal/Modal'
export default {
  name: "App",
  components: {
    Navigation, Modal
  },
  async mounted() {
    if (Cookie.isset("user") && Cookie.findCookie("user") != "null") {
        await this.$store.dispatch("UserModule/getUserInfoNotCached");
        await this.$store.dispatch("CartModule/getCart");
    }
  },
      watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || to.name;
            }
        },
    }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
     