<template>
  <v-tab-item>
    <v-container>
      <div class="">
        <h3 class="grey--text darken-4 font-weight-light">Basic Information</h3>
        <v-form ref="form">
          <v-combobox
            chips
            multiple
            v-model="basic.preference.content"
            :error-messages="basic.preference.error"
            :error-count="basic.preference.error.length"
            label="Prefered Tag"
            hint="Enter your prefered tags, this enable you to easily filter the food that is revelant to you"
            hint-persistent
            clearable
            menu-props="{closeOnClick: true}"
            prepend-icon="mdi-filter"
          >
          </v-combobox>
          <div class="">
            <div class="text-right">
              <v-btn color="primary" class="mt-2 mr-4" @click="saveBasic">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Save
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>

      <v-divider></v-divider>


      <v-divider></v-divider>
      <h3 class="grey--text darken-4 font-weight-light">
        Edit sensitive infromation
      </h3>
      <div class="">
        <v-card class="mx-auto" tile>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn small class="primary" @click="$router.push({ name: 'Change Password' })">Change Password</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="m-0"></v-divider>
        </v-card>
      </div>
    </v-container>
  </v-tab-item>
</template>

<script>
import axios from "axios";
import User from "../../../../../service/user.service";
import InputValidator from "../../../../../utility/inputValidator";
export default {
  name: "UserSettings",
  data() {
    return {
      basic: {
        username: {
          content: "",
          error: [],
        },
        preference: {
          content: ["Chinese", "Seafood"],
          error: [],
        },
      },
    };
  },
  methods: {
    async saveBasic() {
      InputValidator.clearError(this.basic);
      const user = new User();
      var result;
      try {
        result = await user.update({
          preference: this.basic.preference.content,
        });
        await this.$store.dispatch("UserModule/getUserInfoNotCached");
      } catch ({ content }) {
        InputValidator.mergeError(this.basic, content);
        return;
      }
    },
  },
  async created() {
    const userInfo = await this.$store.getters["UserModule/getUser"];
    console.log({ userInfo });
    this.basic.username.content = userInfo.username;
    this.basic.preference.content = userInfo.preference;
  },
};
</script>

<style>
</style>