<template>
  <v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="mx-auto my-3 p-3">
      <v-btn
        class="float-right"
        color="primary"
        @click="$router.push({ name: 'Create Coupon' })"
        >Create new</v-btn
      >
      <h1>Coupon</h1>
      <v-container
        class=""
        :class="
          $store.state.MiscModule.isMobile
            ? ''
            : 'd-flex justify-content-between'
        "
      >
        <v-btn-toggle
          class="mt-2"
          :style="
            $store.state.MiscModule.isMobile
              ? 'flex-direction: column; width:100%'
              : ''
          "
          v-model="searchTerm.type"
          mandatory
        >
          <v-btn color="primary"> Active </v-btn>
          <v-btn color="primary"> Expired </v-btn>
          <v-btn color="primary"> Inactive </v-btn>
        </v-btn-toggle>
      </v-container>

      <v-container class="d-flex">
        <v-text-field
          v-model="searchTerm.code"
          name="Coupon"
          label="Coupon"
          hint="Please enter the coupon code"
        ></v-text-field>

        <v-btn color="primary" @click="search">Find</v-btn>
      </v-container>
      <v-container class="border">
        <v-list two-line>
          <template v-for="coupon in coupons">
            <div class="" :key="coupon._id">
              <v-list-item>
                <template>
                  <v-list-item-content>
                    <div class="d-flex justify-content-between">
                      <div class="d-block">
                        <p class="mr-3">
                          Coupon Code : <code>#{{ coupon.code }}</code>
                        </p>
                        <p class="mr-3">Can be used : {{ coupon.duration }}</p>
                        <p class="mr-3" v-if="coupon.type == 'discount'">
                          Coupon Discount : {{ coupon.value }}%
                        </p>
                        <p class="mr-3" v-else>
                          Coupon Amount : {{ coupon.value }}%
                        </p>
                        <p class="mr-3" v-if="coupon.min_amount">
                          Min Amount : {{ coupon.min_amount || "0" }}
                        </p>
                        <p class="mr-3">
                          Redemption: {{ coupon.used_times }} (Max Limit:
                          {{ coupon.max_redemptions || "Unlimited" }})
                        </p>
                        <br />
                        <p class="mr-3">
                          Expire On: {{ formatDate(coupon.redeem_by) }}
                        </p>
                      </div>
                      <div class="d-flex">
                        <v-tooltip class="mr-2" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn color="grey" disabled> Edit </v-btn>
                            </div>
                          </template>
                          <span>
                            Currently, the payment vendor doesn't support coupon
                            updating, <br />
                            because of that you will need to delete and remake
                            the coupon
                          </span>
                        </v-tooltip>
                        <v-btn color="red" class="white--text" @click="deleteCoupon(coupon._id)">Delete</v-btn>
                      </div>
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>
        </v-list>
      </v-container>

      <div class="text-center">
        <v-pagination
          v-if="this.coupons.length != 0"
          v-model="searchTerm.page"
          :length="pagination.totalPages"
          @input="search"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import Coupon from "../../../../service/coupon.service";
export default {
  name: "ListCoupon",
  data: function () {
    return {
      isActive: true,
      searchTerm: {
        code: "",
        type: 0,
        limit: 5,
        page: 0,
      },
      pagination: {
        totalPages: 0,
      },
      coupons: [],
    };
  },
  methods: {
    formatDate: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm a");
    },
    search: async function () {
      const { code, type, limit, page } = this.searchTerm;
      const coupon = new Coupon();
      try {
        var status = "";
        if (type == 0) {
          status = "active";
        }
        if (type == 1) {
          status = "expired";
        }
        if (type == 2) {
          status = "inactive";
        }
        var { content } = await coupon.getAllCouponAdminSearch(
          code,
          status,
          limit,
          page
        );
        this.coupons = content.hits;
        this.pagination.totalPages = content.totalPages;
        this.pagination.page = page;
      } catch (error) {
        alert("error getting coupons");
      }
    },
    deleteCoupon: async function (id) {
        const target = this.coupons.find(coupon => coupon._id == id);

        if (!confirm(`Are you sure you want delete ${target.code} ?`)) {
            return;
        }

        try {
            const coupon = new Coupon();
            const {content} = await coupon.delete(id)
            await this.$store.dispatch("ModalModule/success", {
                title: `Coupon has been deleted`,
                content: content,
                buttonContent: "Ok",
            });
            this.search();
        } catch (error) {
            await this.$store.dispatch("ModalModule/error", {
                title: `Could not delete coupon`,
                content: content,
                buttonContent: "Ok",
            });
        }
    }
  },
  mounted: function () {
    this.search();
  },
  watch: {
    "searchTerm.type": function (val) {
      this.search();
    },
  },
};
</script>

<style>
</style>