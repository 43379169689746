<template>
  <div>
    <v-divider></v-divider>
    <v-list-item class="position-relative" @click="order.status == 'Paid' ? $router.push({name: 'ViewReceipt', params: {id: order._id}}) : null">
      <v-avatar class="profile mr-5" color="grey" size="64" tile>
        <v-img
          :src="
            order.dishs[0].dish.image
              ? order.dishs[0].dish.image
              : require('@/assets/placeholder.png')
          "
        ></v-img>
      </v-avatar>

      <v-list-item-content>
        <v-container dark class="d-block">
          <p
            v-for="(orderItem, orderItemIndex) in order.dishs"
            v-bind:key="orderItemIndex"
            class="text-caption m-0"
            :class="
              orderItemIndex < 2
                ? 'text-caption m-0'
                : 'text-caption text-muted m-0 font-italic'
            "
          >
            <span v-if="orderItemIndex < 2"
              >{{ orderItem.dish.title }} x {{ orderItem.quantity }} =
              {{ orderItem.total.toFixed(2) }} MYR</span
            >
            <span v-else>And {{ order.dishs.length - 2 }} more</span>
          </p>
          
          <OrderStatus :order="order"></OrderStatus>
          <v-divider></v-divider>

          <div class="d-flex justify-content-between">
            <h6 class="">
              Total of {{ (order.stripePayment.paid_amount / 100  || order.total.toFixed(2)) }} MYR  

              <span class="green--text" v-if="order.isPaid && (order.stripePayment.paid_amount / 100  != order.total) && (order.stripePayment.paid_amount != null)">
                (- {{(order.total - (order.stripePayment.paid_amount / 100 || order.total)).toFixed(2)}} MYR)
              </span>
              <v-chip 
              label
              small 
              class="ml-3 white--text"
              v-if="order.status == 'Paid'"
              :color="paymentColor(order.stripePayment.paymentMethod)

              ">
              {{order.stripePayment.paymentMethod}}
              </v-chip>
              <!-- <span v-if="order.discount.containDiscount" class="text-caption" color="green">(-{{order.discount.value * 100}}% Discount)</span> -->
            </h6>

            <small>Created on {{ moment(order.created) }}</small>
            <small
              >Preparation date on {{ getPreparationHour() }}</small
            >
          </div>
        </v-container>
      </v-list-item-content>

    </v-list-item>
  </div>
</template>

<script>
import moment from "moment";
import OrderStatus from "./partial/OrderStatus"
export default {
  name: "order",
  props: ["order"],
  components: {
    OrderStatus
  },
  methods: {
    moment: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm a");
    },
    getPreparationHour() {
      const {date, batch} = this.order.preparation;
      var orderDate = moment(date).set({ hour: parseInt(batch.hour, 10)}).toDate();
      return moment(orderDate).format("DD MMMM YYYY, h:mm a")
    },
    paymentColor: function (type) {
      switch (type) {
        case "grabpay":
          return "green"
        case "card": 
          return "primary"
        default:
          return "primary"
      }
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.float-right {
  float: right;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
  right: 0;
}
</style>