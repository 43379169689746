<template>
  <div class="m-auto pt-5">
    <h1 class="text-center">Products</h1>
    <PromotionShowcaseListVue fluid v-bind:promotions="promotions" :style="($store.state.MiscModule.isMobile)? '' : 'max-width: 68.9%'"></PromotionShowcaseListVue>
    <ListDish  style="max-width: 70%"   v-if="!loading" v-bind:items="items"></ListDish>
    <ListDishLoader style="max-width: 70%"  v-else v-bind:items="limit"></ListDishLoader>
  </div>
</template>


<script>
// @ is an alias to /src
import ListDish from "../dishes/partial/ListDish";
import Dish from "../../service/dish.service"
import ListDishLoader from "../dishes/partial/ListDishLoader"
import PromotionShowcaseListVue from '../partial/promotionShowcase/PromotionShowcaseList.vue';
import Promotion from "../../service/promotion.service"
export default {
  name: "Home",
  components: {
    ListDish,
    ListDishLoader,
    PromotionShowcaseListVue
  },
  data() {
    return {
      loading: true,
      items: [
      ],
      promotions : [],
      page: 0,
      limit: 100
    };
  },
  created: async function() {
    const dish = new Dish();
    const promotion = new Promotion();
    try {
      this.loading = true;
      const dishResult = await dish.searchDish(
        null, null, null,
        this.page,
        this.limit
      )
      this.items = dishResult.content.hits

      const promotionResult = await promotion.getAllPromotion()
      this.promotions = promotionResult.content
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error)
    }
  },
};
</script>
