<template>
  <div class="">
    <v-chip
      class="mt-1 ml-1 text-white"
      color="green"
      small
      label
      v-if="order.isCollected"
    >
      Ready to collect
    </v-chip>
    <v-chip
      class="mt-1 ml-1 text-white"
      color="green"
      small
      label
      v-else-if="order.isCompleted"
    >
      Ready to collect
    </v-chip>
    <v-chip v-else class="mt-1" color="primary" small label>
      {{ order.status }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "OrderStatus",
  props: ["order"],
};
</script>

<style>
</style>