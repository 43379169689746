var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-carousel',{staticClass:"pb-3",attrs:{"cycle":"","height":"240","interval":"10000","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.promotions),function(promotion){return _c('v-carousel-item',{key:promotion._id,staticClass:"fill-height bottom-gradient",on:{"click":function($event){return _vm.$router.push({
          name : 'Display Promotion Detail',
          params: {
              id: promotion._id
          }
      })}}},[_c('v-img',{staticStyle:{"position":"absolute","width":"100%","height":"100%","filter":"blur(6px)","-webkit-filter":"blur(6px)","-moz-filter":"blur(6px)"},attrs:{"src":promotion.dishs[0].image
            ? promotion.dishs[0].image
            : require('@/assets/placeholder.png')}}),_c('v-overlay',{attrs:{"absolute":true,"value":"true"}},[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(promotion.name))]),(promotion.coupon)?_c('p',{staticClass:"mt-2 text-center"},[_vm._v(" Applicable Coupon : "),_c('v-chip',{staticClass:"black--text",attrs:{"label":"","small":"","color":"white"}},[_vm._v("#"+_vm._s(promotion.coupon.code))])],1):_vm._e(),_c('p',{staticClass:"text-truncate",staticStyle:{"max-width":"70vw"}},[_vm._v(" This promotion contains : "+_vm._s(_vm.truncateList(promotion.dishs, 4) .dishs.map(function (dish) { return dish.title; }) .join(", "))+" "),(_vm.truncateList(promotion.dishs, 4).truncated)?_c('span',[_vm._v(" and "+_vm._s(_vm.truncateList(promotion.dishs, 4).truncated)+" more dishes ")]):_vm._e()])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }