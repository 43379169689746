<template>
  <v-container>
    <salesVue></salesVue>
    <v-card max-width="70%" class="pt-5 mx-auto">
      <h1 class="m-5">Admin Tools</h1>
      <v-container> </v-container>
      <v-divider></v-divider>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>List User</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ListUser></ListUser>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Create Worker</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <CreateWorker></CreateWorker>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel @click="$router.push('/dish/create')">
          <v-expansion-panel-header>
            <h3>Add Dish</h3>
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel
          @click="
            $router.push({
              name: 'List Coupon',
            })
          "
        >
          <v-expansion-panel-header>
            <h3>List Coupon</h3>
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel
          @click="
            $router.push({
              name: 'List Promotion',
            })
          "
        >
          <v-expansion-panel-header>
            <h3>List Promotion</h3>
          </v-expansion-panel-header>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Batches</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Batches></Batches>
          </v-expansion-panel-content>
        </v-expansion-panel>
                <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Addons</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Addon></Addon>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import ListUser from "../../worker/Tools/ListUser/ListUser";
import CreateWorker from "./CreateWorker/CreateWorker";

import Batches from "./Batches/Batches";
import Addon from "./Addon/Addon";
import salesVue from "../chart/Sales";
export default {
  name: "AdminTools",
  components: {
    ListUser,
    CreateWorker,
    Batches,
    salesVue,
    Addon
  },
};
</script>

<style>
</style>