<template>
  <v-container >
    <v-container>
      <v-card  max-width="70%" class="pt-5 mx-auto" v-if="user" tile>    
        <v-btn class="primary m-4" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon> Go Back</v-btn>
        <h1 class="text-center">
          Username : {{user.username}} 
          <small>({{user.accountType}})</small>
        </h1>
        <v-divider></v-divider>

        <v-tabs-items v-model="tab">
          <PreviousOrder :orders="orders"></PreviousOrder>

        </v-tabs-items>
      </v-card>
      <v-card  max-width="70%" class="pt-5 mx-auto" v-else tile>
        <h1 class="text-center">
          No Result, perhaps the id is invalid ?
        </h1> 
        <v-col class="text-right">
        <v-btn class="m-4 text-right" @click="$router.go(-1)">Go Back</v-btn>
        </v-col>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import Order from '../../../../service/order.service';
import Worker from '../../../../service/worker.service'
import PreviousOrder from '../../../user/partial/Profile/PreviousOrder/PreviousOrder'
export default {
  name: "AdminDisplayUserProfile",
  data() {
    return {
      user: null,
      orders: [],
      tab: 0

    };
  },
  components: {
    PreviousOrder
  },
  mounted: async function(){
    const order = new Order();
    try {
      const result = await order.userGetRelatedOrderById(this.$route.params.id)
      this.orders = result.content;
    } catch (error) {
      
    }
  },
  async created() {
    if (this.$route.params.id) {
      const worker = new Worker();
      const result = await worker.getUserInfo(this.$route.params.id);
      if (result.error) {
        return;
      }
      this.user = result.content;
    }
    else { console.log("does not contain") }
    // const findUser = new User();
    // findUser.getLatestUserInfo()
  }
};
</script>

