import axios from '../helper/axiosWithHeader'


export default class Coupon {
    
    async insertCoupon(
        name,
        code,
        duration,
        type,
        value,
        max_redemptions,
        redeem_by,
        valid) {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/coupon`, {
            name,
            code,
            duration,
            type,
            value,
            max_redemptions,
            redeem_by,
            valid
        });
    }

    async delete(id) {
        return await axios.delete(`${process.env.VUE_APP_ROOT_API}/coupon/${id}`);
    }

    async findCoupon(couponCode) {
        const coupons = await axios.get(`${process.env.VUE_APP_ROOT_API}/coupon/${couponCode}`);
        return coupons;
    }

    async findCouponById(id) {
        const coupons = await axios.get(`${process.env.VUE_APP_ROOT_API}/coupon/admin/${id}`);
        return coupons;
    }

    async getAllCouponAdmin(couponCode) {
        const coupons = await axios.get(`${process.env.VUE_APP_ROOT_API}/coupon`);
        return coupons;
    }
    async getAllValidCouponAdmin(couponCode) {
        const coupons = await axios.get(`${process.env.VUE_APP_ROOT_API}/coupon/valid`);
        return coupons;
    }

    async getAllCouponAdminSearch(code, status, limit, page) {
        const coupons = await axios.post(`${process.env.VUE_APP_ROOT_API}/coupon/search`, {
            code, status, limit, page
        });
        return coupons;
    }
}