<template>
  <v-container>
    <v-card max-width="70%" class="pt-5 mx-auto">
      <h1 class="m-5">Worker Tools</h1>
      <v-divider></v-divider>
            <ListUser></ListUser>
    </v-card>
  </v-container>
</template>

<script>
import ListUser from "./ListUser/ListUser";
export default {
  name: "WorkerTools",
  components: {
    ListUser,
  },

};
</script>

<style>
</style>