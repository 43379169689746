import axios from "axios";
import Cookie from '../utility/cookie'
import UserModel from '../store/stateModel/user.state.model'
import Auth from "./auth.service";

export default class Worker {
    constructor () {
        this.user = UserModel;
    }
    async getUserInfo(id) {
        try {
            const result = await axios.get(`${process.env.VUE_APP_ROOT_API}/worker/user/${id}`);
            return result
        } catch (err) {
            return err;
        }

    }

    async getOrders(date) {
        return await axios.get(`${process.env.VUE_APP_ROOT_API}/worker/order`, {params: {date}})
    }

    async setOrderAsComplete(id) {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/worker/order/complete/${id}`)
    }

    async setOrderAsCollected(id) {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/worker/order/collect/${id}`)
    }

    async getUserList(username, accountType, page, limit = 5) {
        var result = []
        try {
            var searchBody = {
                username: username,
                accountType: accountType,
                limit: limit,
                page: page
            }
            result = await axios.get(`${process.env.VUE_APP_ROOT_API}/worker/user/find/`, {params: searchBody})
            return result;
        }
        catch (err) {
            return err
        }
    }
}