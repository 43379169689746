
import User from "../../service/user.service";
import Cookie from "../../utility/cookie"






const UserModule = {
    namespaced: true,
    state: {
        loading: false,
        user: null,
        error: null
    },
    getters: {
        getUser(state) {
            return state.user;
        }
    },
    mutations: {
        onError(state, req){
            state.error = req;
            state.user = null;
            state.loading = false;
        },
        onSuccess(state, req){
            state.loading = false;
            state.error = null;
            state.user = req;            
        },
        onFind(state) {
            state.loading = true;
        },
        clearUser(state) {
            state.loading = false;
            state.error = null;
            state.user = null; 
        },
        updateData(state, data) {
            state.user = data; 
            state.error = null;
        }

    },
    actions: {
        async getUserInfo({commit}) {
            const LoggedUser = new User();
            var result = await LoggedUser.getCachedUserInfo();
            commit("onFind")
            if (result) {
                commit("onSuccess", result)
            }
            else {
                commit("onError", result)
            }
        },
        async getUserInfoNotCached({commit}) {
            const LoggedUser = new User();
            var result = await LoggedUser.getLatestUserInfo();
            commit("onFind")
            if (result) {
                commit("onSuccess", result)
            }
            else {
                commit("onError", result)
            }
        },
        async logout({commit}) {
            commit("clearUser");
        },
        async login({commit}, data) {
            commit("updateUser", data)
        }
    },
}

export default UserModule;