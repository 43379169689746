<template>
  <v-container>
    <h1>Addon</h1>
    <v-container>
      <v-btn class="" color="primary" elevation="2" @click="loadModal()"
        >Create</v-btn
      >
    </v-container>
    <v-list three-line>
      <template v-for="(addon, index) in addons">
        <v-list-item :key="addon._id">
          <v-list-item-content>
            <div class="d-flex justify-content-between">
              <p>
                {{ addon.name }} <v-chip color="primary" small>Price : {{ (addon.price == 0)? 'free' : addon.price }}</v-chip>
                {{ addon.hidden ? "(hidden)" : "" }}
              </p>
              <div class="">
                <v-btn
                  class="mr-2"
                  color="warning"
                  @click="loadModal(addon._id)"
                  >Edit</v-btn
                >
                <v-btn color="error" @click="removeAddon(addon._id)">{{
                  addon.hidden ? "Unhide" : "Delete"
                }}</v-btn>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          class="m-0"
          :key="index"
          v-if="index < addons.length - 1"
        ></v-divider>
      </template>
    </v-list>
    <AddonModel
      @loadAddon="loadAddon()"
      :addonModal="addonModal"
      :update="update"
    />
  </v-container>
</template>

<script>
import AddonModel from "./partial/AddonModal";
import Addon from "../../../../service/addon.service";
export default {
  name: "Addon",
  data: function () {
    return {
      addonModal: {
        state: false,
        id: null,
        name: {
          content: "",
          error: [],
        },
        price: {
          content: 1,
          error: [],
        },
      },
      update: false,
      addons: [],
    };
  },
  components: { AddonModel },
  methods: {
    loadAddon: async function () {
      const addon = new Addon();
      try {
        const result = await addon.getListOfAddon();
        this.addons = result.content;
      } catch (error) {
        console.log(error);
      }
    },
    loadModal: async function (id = null) {
      if (id == null) {
        this.update = false;
        this.addonModal.addon = null;
        this.addonModal.state = true;
        this.addonModal.name.content = "";
        this.addonModal.price.content = 0;
        return;
      }
      const addon = this.addons.find((b) => b._id == id);
      if (!addon) {
        this.$store.dispatch("ModalModule/error", {
          title: "Error",
          content: `Could not find the addon`,
          buttonContent: "OK",
        });
      }
      this.update = true;
      this.addonModal.name.content = addon.name;
      this.addonModal.price.content = addon.price;
      this.addonModal.addon = addon;
      this.addonModal.state = true;
    },
    removeAddon: async function (id) {
      if (!confirm("are you sure you want to delete the addon ?")) {
          return;
      }
      const addon = new Addon();
      try {
        const { name, price } = this.addonModal;
        const result = await addon.deleteAddon(id);
        this.$store.dispatch("ModalModule/success", {
          title: "Addon deleted successfully",
          content: `${result.content}`,
          buttonContent: "OK",
        });
        this.loadAddon();
        this.$emit("loadAddon", "");
      } catch (error) {
        console.log(error);
      }
    },
    priceFormat: function (price) {
      if (price < 12) {
        return `${price}:00am`;
      } else if (price == 12) {
        return `${price}:00pm`;
      }
      return `${price - 12}:00pm`;
    },
  },
  mounted: async function () {
    await this.loadAddon();
  },
};
</script>

<style>
</style>