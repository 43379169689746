const MiscModule = {
    namespaced: true,
    state: {
        isMobile: false,
    },
    getters: {
        isMobile(state) {
            return state;
        }
    },
    mutations: {
        updateIsMobile(state, {isMobile, screenWidth}) {
            state.isMobile = isMobile
        }

    },
    actions: {
        async updateMobileState({commit, dispatch, state}, screenWidth) {
            if (screenWidth < 1200) {
                commit("updateIsMobile", {screenWidth, isMobile: true});
            }
            else {
                commit("updateIsMobile", {screenWidth, isMobile: false})
            }
        },
        async logout({commit,dispatch}) {
            const LoggedUser = new User();
            LoggedUser.logout();
            commit("onLogout")
            dispatch("UserModule/logout", null, {root: true})
        }
    },
}

export default MiscModule;