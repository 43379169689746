<template>
<v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="p-3">
      <h1>Reset Password</h1>
      <v-divider></v-divider>
      <p>Please enter your email address, and we will send you instruction to reset the password</p>
      <v-text-field
        v-model="userData.email.content"
        :error-messages="userData.email.error"
        :error-count="1"
        label="Email Address"
        required
      >
      </v-text-field>
      
      <div class="d-flex justify-content-between mt-2">
        <v-btn @click="$router.push({ name: 'Profile' })" color="primary"
          >Back</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="forgetPassword" color="primary">Reset Password</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import User from "../../service/user.service";
import InputValidator from "../../utility/inputValidator";

export default {
    name: "ForgetPassword",
    data: function () {
        return {
            userData : {
                email : {
                    content: "",
                    error: []
                }
            }
        }
    },
    methods: {
        forgetPassword: async function(){
            const user = new User();
            InputValidator.clearError(this.userData);
            try {
                var result = await user.forgetPassword(this.userData.email.content);
                const {content} = result;
                this.$store.dispatch("ModalModule/success", {
                    title: "Recovery Email Sent",
                    content: content,
                    buttonContent: "Ok",
                });
            } catch ({content}) {
                InputValidator.mergeError(this.userData, content)
            }

        }
    }
}
</script>

<style>

</style>