const unauthenticated = [
    {icon: "home", title: "Home" , path: "/"},
    {icon: "bowl-mix", title: "Dish" , path: "/dish"},
]


const admin =  [
    {icon: "home", title: "Home" , path: "/"},
    {icon: "receipt", title: "Order" , path: "/worker/order"},
    {icon: "qrcode-scan", title: "Scan QR code" , path: "/worker/receipt/scan"},
    {icon: "account", title: "Profile" , path: "/user/profile"},
    {icon: "account", title: "Admin Tools" , path: "/admin/tools"},
    {icon: "bowl-mix", title: "Dish" , path: "/dish"},
    {icon: "cart", title: "Cart" , path: "/cart"},
]

const worker =  [
    {icon: "home", title: "Home" , path: "/"},
    {icon: "receipt", title: "Order" , path: "/worker/order"},
    {icon: "qrcode-scan", title: "Scan QR code" , path: "/worker/receipt/scan"},
    // {icon: "account", title: "Profile" , path: "/user/profile"},
    {icon: "account", title: "Worker Tools" , path: "/worker/tools"},
    // {icon: "bowl-mix", title: "Dish" , path: "/dish"},
    // {icon: "cart", title: "Cart" , path: "/worker/cart"},
]
const user =  [
    {icon: "home", title: "Home" , path: "/"},
    {icon: "account", title: "Profile" , path: "/user/profile"},
    {icon: "bowl-mix", title: "Dish" , path: "/dish"},
    {icon: "cart", title: "Cart" , path: "/cart"},
]

const commonUnauthenticated = [
    {icon: "login", title: "login" , path: "/login"},
    {icon: "account-plus", title: "signup" , path: "/signup"},
]

const commonAuthenticated = [
    {icon: "logout", title: "logout" , path: "/logout"},
]


const getNavigationRules = (type) => {
  switch (type) {
      case "admin":
            return [...admin, ...commonAuthenticated]
      case "worker":
            return [...worker, ...commonAuthenticated]
      case "notLogin":
            return [...unauthenticated, ...commonUnauthenticated]
      default:
            return [...user, ...commonAuthenticated]
  }
}

export {unauthenticated, admin, worker, commonAuthenticated, commonUnauthenticated}
export default getNavigationRules
