<template>
  <v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="p-3">
      <h1>Change password</h1>
      <v-divider></v-divider>
      <v-text-field
        v-model="password.currentPassword.content"
        :error-messages="password.currentPassword.error"
        :error-count="1"
        :type="password.currentPassword.show ? 'text' : 'password'"
        label="Current Password"
        required
      >
        <v-icon
          slot="append"
          @click="
            password.currentPassword.show = !password.currentPassword.show
          "
          >{{
            !password.currentPassword.show ? "mdi-eye" : "mdi-eye-off"
          }}</v-icon
        >
      </v-text-field>
      <v-text-field
        v-model="password.newPassword.content"
        :error-messages="password.newPassword.error"
        :error-count="1"
        :type="password.newPassword.show ? 'text' : 'password'"
        label="New Password"
      >
        <v-icon
          slot="append"
          @click="
            password.newPassword.show = !password.newPassword.show
          "
          >{{
            !password.newPassword.show ? "mdi-eye" : "mdi-eye-off"
          }}</v-icon
        >
      </v-text-field>
      <v-text-field
        v-model="password.conPassword.content"
        :error-messages="password.conPassword.error"
        :error-count="1"
        :type="password.conPassword.show ? 'text' : 'password'"
        label="Re Enter Password"
      >
        <v-icon
          slot="append"
          @click="
            password.conPassword.show = !password.conPassword.show
          "
          >{{
            !password.conPassword.show ? "mdi-eye" : "mdi-eye-off"
          }}</v-icon
        >
      </v-text-field>
      <div class="d-flex justify-content-between">
        <v-btn @click="$router.push({name: 'Profile'})" color="primary">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="changePassword" color="primary">Change Password</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import InputValidator from "../../utility/inputValidator";
import User from "../../service/user.service";
export default {
  name: "ChangePassword",
  data: function (v) {
    return {
      password: {
        currentPassword: {
          content: "",
          error: [],
          show: false,
        },
        newPassword: {
          content: "",
          error: [],
          show: false,
        },
        conPassword: {
          content: "",
          error: [],
          show: false,
        },
      },
    };
  },
  methods: {
    async changePassword() {
      InputValidator.clearError(this.password);

      const user = new User();
      try {
        const result = await user.changePassword(
          this.password.currentPassword.content,
          this.password.newPassword.content,
          this.password.conPassword.content
        );
        this.$store.dispatch("ModalModule/success", {
          title: "Password change successful!!",
          content: "Your password has been changed",
          buttonContent: "Ok",
        });
      } catch ({ content }) {
        InputValidator.mergeError(this.password, content);
      }
    },
  },
};
</script>

<style>
</style>