<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-img
        :src="
          cartItem.dish.image
            ? cartItem.dish.image
            : `../../assets/placeholder.png`
        "
        max-height="128px"
        max-width="128px"
        lazy-src="../../../assets/placeholder.png"
      ></v-img>
    </v-list-item-avatar>

    <v-list-item-content class="mb-0 pt-0">
      <v-list-item-title>
        <span v-if="cartItem.itemType == 'dish'">
            {{ cartItem.dish.title }}
        </span>
        <span v-else>
            {{ cartItem.dish.title }}
        </span>
         - Price : {{ cartItem.total_without_quanitity }} X {{ cartItem.quantity }} = <b>{{ cartItem.total }} MYR</b>
        
      </v-list-item-title>
                <v-list-item-subtitle
            >Addons:
            {{ addonDisplay(cartItem).join(", ") }}</v-list-item-subtitle
          >
      <small v-if="cartItem.remarks">Remarks : {{ cartItem.remarks }}</small>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "CartListItem",
  props: ["cartItem"],
  methods: {
      addonDisplay(cartItem) {
      const addons = cartItem.addons.map(
        (e) => `${e.addon.name} - Price : ${e.price}`
      );
      if (addons.length == 0) return ["None"];
      return addons;
    },
  }
};
</script>

<style>
</style>