<template>
  <v-app-bar app color="dark" dark>
    <v-btn @click="goHome" text>
      <v-icon>mdi-bowl-mix</v-icon>
      <span class="mx-2">Smart Canteen</span>
    </v-btn>

    <v-btn
      :to="item.path"
      text
      v-for="item in navigation"
      v-bind:key="item.title"
    >
      <v-icon>mdi-{{ item.icon }}</v-icon>
      <span class="mx-2" v-if="item.title == 'Cart' && cart">
        <v-badge
          :content="cart.dishs.length"
        >
        {{ item.title }}
        </v-badge>
      </span>
      <span v-else>{{ item.title }}</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "fullSizeNavigation",
  data: function () {
      return {
          navigation: this.navigationItem,
      }
  },
  methods: {
    goHome(){this.$router.push("/")},
  },
  props: {
    navigationItem: { type: Array, default: () => ({}) },
    userInfo: { type: Object, default: () => ({}) },
    cart: { type: Object, default: () => (null) },
  },
  watch: {
    "navigationItem": function (val) {this.navigation = this.navigationItem},
  },
};
</script>