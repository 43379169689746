<template>
  <v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="mx-auto my-3 p-3">
      <v-btn
        color="primary"
        class="mb-3"
        @click="
          $router.push({
            name: 'List Promotion',
          })
        "
        >Back</v-btn
      >
      <h1>Promotion</h1>
      <v-text-field
        name="Promotion Name"
        label="Promotion Name"
        hint="Please enter the name of the promotion"
        v-model="promotionData.name.content"
        :error-messages="promotionData.name.error"
      ></v-text-field>

      <v-select
        :items="coupons"
        label="Coupon"
        item-value="_id"
        item-text="_id"
        hint="Coupon that user can use to enjoy more discounts"
        persistent-hint
        clearable
        v-model="promotionData.coupon.content"
        :error-messages="promotionData.coupon.error"
      >
        <template v-slot:selection="{ item }">
          {{ item.name == item.code ? "" : item.name }} #{{ item.code }}
          <span class="ml-3">
            -
            <span>{{ item.type == "amount" ? "Deduct" : "Discount" }}</span>
            {{ item.value }}
            <span>{{ item.type == "amount" ? "MYR" : "%" }}</span>
          </span>
        </template>
        <template v-slot:item="{ item }">
          {{ item.name == item.code ? "" : item.name }} #{{ item.code }}
          <span class="ml-3">
            -
            <span>{{ item.type == "amount" ? "Deduct" : "Discount" }}</span>
            {{ item.value }}
            <span>{{ item.type == "amount" ? "MYR" : "%" }}</span>
          </span>
        </template>
      </v-select>

      <v-select
        :items="dishs"
        item-value="_id"
        item-text="_id"
        label="Dish"
        hint="List of dish related to this promotion"
        clearable
        multiple
        v-model="promotionData.dishs.content"
        :error-messages="promotionData.dishs.error"
      >
        <template v-slot:item="{ item }">
          <div class="d-flex align-items-center ">
            <div class="">
              <v-img
                max-width="64"
                max-height="64"
                width="64"
                height="64"
                :src="item.image"
                lazy-src="@/assets/placeholder.png"
              ></v-img>
            </div>
            <div class="p-3">
              <p>{{ item.title }} - {{ item.price.toFixed(2) }} MYR</p>
              <p>Available Addon : {{ addonDisplay(item).join(",") }}</p>
            </div>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <v-chip small color="primary">{{ item.title }}</v-chip>
        </template>
      </v-select>

      <p>Should the promotion be immediately available ?</p>
      <v-checkbox
        v-model="promotionData.hidden.content"
        label="Make it hidden"
      ></v-checkbox>
      <div class="d-flex justify-content-end">
        <v-btn color="primary" @click="updatePromotion()"
          >Update Promotion</v-btn
        >
      </div>
    </v-card>
  </v-container>
</template>


<script>
import Dish from "../../../../service/dish.service";
import Coupon from "../../../../service/coupon.service";
import Promotion from "../../../../service/promotion.service";
import InputValidator from "../../../../utility/inputValidator";
export default {
  name: "EditPromotion",
  data: function () {
    return {
      promotionData: {
        name: {
          content: "",
          error: [],
        },
        coupon: {
          content: "",
          error: [],
        },
        dishs: {
          content: [],
          error: [],
        },
        hidden: {
          content: false,
          error: [],
        },
      },
      coupons: [],
      dishs: [],
    };
  },
  methods: {
    loadCoupons: async function () {
      const coupon = new Coupon();
      const { content } = await coupon.getAllCouponAdmin();
      this.coupons = content;
    },
    loadPromotion: async function () {
      const id = this.$route.params.id;
      const promotion = new Promotion();
      const { content } = await promotion.findPromotionById(id);
      this.promotionData.name.content = content.name;
      this.promotionData.coupon.content = content.coupon._id;
      this.promotionData.dishs.content = content.dishs.map((dish) => dish._id);
      this.promotionData.hidden.content = content.hidden;
    },
    loadDishs: async function () {
      const dish = new Dish();
      const { content } = await dish.searchDish(null, null, null, 1, 1000);
      this.dishs = content.hits;
    },
    addonDisplay: function (dish) {
      const addons = dish.addonSelection.map(
        (e) => `${e.addon.name} - Price : ${e.price}`
      );
      if (addons.length == 0) return ["None"];
      return addons;
    },
    updatePromotion: async function () {
      const { name, coupon, dishs, hidden } = this.promotionData;
      const promotion = new Promotion();
      InputValidator.clearError(this.promotionData);
      try {
        const result = await promotion.updatePromotion(
          name.content,
          coupon.content,
          dishs.content,
          hidden.content,
          this.$route.params.id
        );
        await this.$store.dispatch("ModalModule/success", {
          title: `Promotion updated`,
          content: `The promotion ${result.content.name} has been updated`,
          buttonContent: "Ok",
        });
        this.$router.push({ name: "List Promotion" });
      } catch ({ content }) {
        InputValidator.mergeError(this.promotionData, content);
      }
    },
  },
  mounted: async function () {
    await this.loadCoupons();
    await this.loadDishs();
    await this.loadPromotion();
  },
};
</script>

<style>
</style>