import axios from '../helper/axiosWithHeader'

export default class Batch {
    async getBatches() {
        return await axios.get(`${process.env.VUE_APP_ROOT_API}/batch`);
    }

    async getAllBatches() {
        return await axios.get(`${process.env.VUE_APP_ROOT_API}/batch/all`);
    }

    async insertBatch(name, hour){
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/batch`, {
            name: name,
            hour: hour
        });
    }


    async findBatch(id) {
        return await axios.get(`${process.env.VUE_APP_ROOT_API}/batch/${id}`);
    }

    async updateBatch(id, name, hour) {
        return await axios.patch(`${process.env.VUE_APP_ROOT_API}/batch/${id}`, {
            name,
            hour, 
        });
    }

    async removeBatch(id) {
        return await axios.delete(`${process.env.VUE_APP_ROOT_API}/batch/${id}`);
    }

    static hourFormat(hour) {
        if (hour < 12) {
            return `${hour}:00am`
        }
        else if (hour == 12) {
            return `${hour}:00pm`
        }
        return `${hour - 12}:00pm`
    }
}