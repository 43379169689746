<template>
  <v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="p-3">
      <h1>Reset Password</h1>
      <v-divider></v-divider>
      <v-text-field
        v-model="password.recoveryCode.content"
        :error-messages="password.recoveryCode.error"
        :error-count="1"
        :type="password.recoveryCode.show ? 'text' : 'password'"
        label="Recovery Code"
        required
      >
      </v-text-field>
      <v-text-field
        v-model="password.newPassword.content"
        :error-messages="password.newPassword.error"
        :error-count="1"
        :type="password.newPassword.show ? 'text' : 'password'"
        label="New Password"
        required
      >
        <v-icon
          slot="append"
          @click="password.newPassword.show = !password.newPassword.show"
          >{{ !password.newPassword.show ? "mdi-eye" : "mdi-eye-off" }}</v-icon
        >
      </v-text-field>
      <v-text-field
        v-model="password.conPassword.content"
        :error-messages="password.conPassword.error"
        :error-count="1"
        :type="password.conPassword.show ? 'text' : 'password'"
        label="Confirm Password"
        required
      >
        <v-icon
          slot="append"
          @click="password.conPassword.show = !password.conPassword.show"
          >{{ !password.conPassword.show ? "mdi-eye" : "mdi-eye-off" }}</v-icon
        >
      </v-text-field>
      <div class="d-flex justify-content-between mt-2">
        <v-btn @click="$router.push({ name: 'Profile' })" color="primary"
          >Back</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="resetPassword" color="primary">Reset Password</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import User from "../../service/user.service";
import InputValidator from "../../utility/inputValidator";

export default {
  name: "ResetPassword",
  data: function () {
    return {
      user_id: this.$route.params.id,
      password: {
        recoveryCode: {
          content: "",
          error: [],
        },
        newPassword: {
          content: "",
          error: [],
          show: false,
        },
        conPassword: {
          content: "",
          error: [],
          show: false,
        },
      },
    };
  },
  methods: {
    resetPassword: async function () {
      InputValidator.clearError(this.password);

      try {
        const user = new User();
        const result = await user.resetPassword(
          this.user_id,
          this.password.recoveryCode.content,
          this.password.newPassword.content,
          this.password.conPassword.content
        );

        this.$store.dispatch("ModalModule/success", {
          title: "Password change successful!!",
          content: "Your password has been changed",
          buttonContent: "Ok",
        });
        this.$router.push({name : "Home"})
      } catch ({ content }) {
        InputValidator.mergeError(this.password, content);
      }
    },
  },
};
</script>

<style>
</style>