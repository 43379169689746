<template>
    <v-dialog v-model="dishModal.state" width="500">
      <v-card>
        <v-card-text>
          <v-card-title v-if="!update" > Add to Cart </v-card-title>
          <v-card-title v-if="update" > Edit Item </v-card-title>
            <v-container>
              <v-select :items="dish.addonSelection" v-model="dishModal.data.addon" item-value="addon._id" label="Selected Addon" multiple>
                <template v-slot:item="{ item }">
                  {{ item.addon.name }} : {{ item.price }} MYR
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip small color="primary">{{ item.addon.name }} : {{ item.price }} MYR</v-chip>
                </template>
              </v-select>

              <v-text-field v-model="dishModal.data.quantity" type="number" label="Quantity" min="1"></v-text-field>
              <v-text-field v-model="dishModal.data.remarks" label="Remarks"></v-text-field>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dishModal.state = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="!update" text @click="dishModal.state = false; addToCart()">
            Add To Cart
          </v-btn>
          <v-btn color="primary" v-if="update" text @click="dishModal.state = false; updateCart()">
            Edit Item
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import Cart from '../../../service/cart.service';
export default {
    name: "dishModal",
    props: ["dish","dishModal", "update"],
    methods: {
    addToCart: async function(){
      const self = this;
      const cart = new Cart();
      const {addon, quantity, remarks} = this.dishModal.data;
        this.$store.dispatch("CartModule/addDishCart", {id: this.$route.params.id, addon, quantity, remarks})
        .then((res) => {
            if (res.error) {
                var message = (res.content.addon)? res.content.addon.message : res.content.dish.message
                self.$store.dispatch("ModalModule/error", {
                title: "Error",
                content: message[0],
                buttonContent: "OK",
            })
            }
            else {
                self.$store.dispatch("CartModule/getCart")
                self.$store.dispatch("ModalModule/success", {
                  title: "Dish added to cart",
                  content: "you can check it on your cart",
                  buttonContent: "OK",
                });
            }
        })
      
    },
    updateCart: async function(){
      const self = this;
      const cart = new Cart();
      const {addon, quantity, remarks} = this.dishModal.data;
      console.log(addon);
        this.$store.dispatch("CartModule/updateCart", {id: this.dish._id, addon: [...addon], quantity, remarks})
        .then((res) => {
                if (res.error) {
                    var message = (res.content.addon)? res.content.addon.message : res.content.dish.message
                    self.$store.dispatch("ModalModule/error", {
                    title: "Error",
                    content: message[0],
                    buttonContent: "OK",
                })
            }
            else {
                self.$store.dispatch("CartModule/getCart")
                self.$store.dispatch("ModalModule/success", {
                  title: "Dish updated",
                  content: "you can check it on your cart",
                  buttonContent: "OK",
                });
                this.$emit('syncCart', '')
            }
        })
    }
    }
}
</script>

<style>

</style>