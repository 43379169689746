<template>
    <div class=""></div>
</template>
<script>
import Cookie from '../../utility/cookie'

export default {
    name:"Logout",
    created() { 
        Cookie.deleteCookie("user");
        this.$store.dispatch("AuthModule/logout")
        this.$router.push("/login")
    }
}
</script>