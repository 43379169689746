<template>
  <v-container
    fluid
    class="mx-auto"
    :style="$store.state.MiscModule.isMobile ? '' : 'max-width: 70%'"
  >
    <v-overlay :value="loading.state">
      <h3 class="display-3">{{ loading.content }}</h3>
      <div class="mx-auto d-flex">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          size="64"
        ></v-progress-circular>
      </div>
    </v-overlay>
    <div class="d-flex justify-content-between">
      <v-btn color="primary" @click="$router.push({
        name: 'View Dish',
        params: { id: id}
      })"
      ><v-icon>mdi-arrow-left</v-icon> Go back</v-btn
    >
    <v-btn color="red" class="white--text" @click="remove()">Delete</v-btn>
    </div>
    <v-card class="mt-5 mx-auto">
      <h1 class="text-center display-3">Edit Dish</h1>
      <v-img
        class="white--text align-end text-center"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        max-height="300"
        :src="
          dish.dishImage.croppiedImage != ''
            ? dish.dishImage.croppiedImage
            : `../../../../assets/placeholder.png`
        "
        lazy-src="../../../../assets/placeholder.png"
      >
        <div class="p-absolute" style="top: 2%; right: 1%">
          <v-btn
            small
            class="mr-2"
            color="primary"
            @click="dish.dishImage.modal = true"
            >Change Image</v-btn
          >
          <v-btn small color="red" class="white--text" @click="deleteImage()"
            >Remove Image</v-btn
          >
        </div>

        <v-dialog v-model="dish.dishImage.modal" width="500">
          <v-card>
            <v-card-text>
              <cropper
                class="cropper mx-auto border"
                ref="cropper"
                :src="dish.dishImage.rawImage"
                v-if="dish.dishImage.rawImage"
                :stencil-props="{ aspectRatio: 16 / 9 }"
              />
              <v-file-input
                label="Choose an image"
                v-on:change="updateCropper"
                accept="image/jpg, image/jpeg, image/png"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="dish.dishImage.modal = false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="
                  cropImage();
                  dish.dishImage.modal = false;
                "
              >
                Change Picture
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-file-input
          hide-input
          ref="imageInput"
          class="d-none"
          prepend-icon="undefined"
          v-on:change="updateCropper"
        ></v-file-input>
        <h2 class="display-3 z-index">{{ dish.title.content }}</h2>
      </v-img>
      <v-container :class="dish.title.error.length ? 'red lighten-5' : ''">
        <v-text-field
          single-line
          v-model="dish.title.content"
          label="Dish Name"
          :error-messages="dish.title.error"
          :error-count="dish.title.error.length"
          required
        ></v-text-field>
      </v-container>
      <v-container class="bg-primary text-white">
        <h3 class="text-center">Price : {{ dish.price.content }} MYR</h3>
      </v-container>
      <v-container :class="dish.price.error.length ? 'red lighten-5' : ''">
        <v-text-field
          label="Price"
          prepend-icon="mdi-cash"
          hint="Price of the dishes"
          persistent-hint
          v-model="dish.price.content"
          :error-messages="dish.price.error"
          :error-count="dish.price.error.length"
          @keypress="onlyForCurrency"
        ></v-text-field>
      </v-container>

      <v-container>
        <v-combobox
          label="Tags"
          prepend-icon="mdi-tag"
          hint="Please enter the tag of the food"
          :error-messages="dish.tags.error"
          :error-count="dish.tags.error.length"
          persistent-hint
          multiple
          chips
          single-line
          v-model="dish.tags.content"
        >
          <template v-slot:selection="{ item }">
            <v-chip color="primary">
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-container>

      <v-container
        :class="dish.availability.error.length ? 'red lighten-5' : ''"
      >
        <v-select
          label="Availability"
          v-model="dish.availability.content"
          :error-messages="dish.availability.error"
          :error-count="dish.availability.error.length"
          prepend-icon="mdi-calendar-week"
          :items="[
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ]"
          multiple
          chips
          hint="When is the food available"
          persistent-hint
        >
        </v-select>
      </v-container>

      <v-container class="d-flex">
        <p class="mr-2 pt-1">Available Addons</p>
        <div class="">
          <v-chip
            class="mr-2"
            color="primary"
            v-for="addonInfo in dish.dishAddon.addonList"
            :key="addonInfo.id"
          >
            {{ addonInfo.addon.name }} |
            {{ addonInfo.price != 0 ? `${addonInfo.price} MYR` : "Free" }}
            <v-icon @click="editAddon(addonInfo.addon._id)" class="ml-2"
              >mdi-playlist-edit</v-icon
            >
            <v-icon
              @click="removeSelectedAddon(addonInfo.addon._id)"
              class="ml-2"
              >mdi-close</v-icon
            >
          </v-chip>
        </div>

        <v-chip class="mx-2" @click="dish.dishAddon.addonModal.state = true"
          >Include more addons <v-icon>mdi-plus</v-icon></v-chip
        >

        <v-dialog
          v-model="dish.dishAddon.addonModal.state"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{
                dish.dishAddon.addonModal.update
                  ? "Edit the addon of the dish"
                  : "Add addon to dish"
              }}</span>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                label="Addons"
                prepend-icon="mdi-plus"
                @change="updatePrice"
                :items="getAllAddon()"
                v-model="dish.dishAddon.addonModal.value.selectedAddon"
                chips
                hint="Please select an addon"
                persistent-hint
                item-text="name"
                item-value="_id"
              >
                <template v-slot:item="{ item }">
                  {{ item.name }}
                  <v-chip class="ml-3" small color="primary"
                    >Default price : {{ item.price }} MYR</v-chip
                  >
                </template>
              </v-autocomplete>
              <v-text-field
                @keypress="onlyForCurrency"
                type="number"
                min="0"
                :rules="dish.dishAddon.addonModal.value.rule"
                v-model="dish.dishAddon.addonModal.value.price"
                prepend-icon="mdi-cash"
                label="Addon price"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="addAddonToDish">
                {{ dish.dishAddon.addonModal.update ? "Update" : "Save" }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="clearModal()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-divider></v-divider>
      <h2 class="text-center">Description</h2>
      <v-container
        :class="dish.description.error.length ? 'red lighten-5' : ''"
      >
        <v-textarea
          counter
          background-color="grey lighten-2"
          label=" Dish Description"
          v-model="dish.description.content"
          :error-messages="dish.description.error"
          :error-count="dish.description.error.length"
          persistent-hint
          hint="Brief introduction to the dishes"
        ></v-textarea>
      </v-container>

      <v-container
        :class="dish.maxOrderLimitPerOrder.error.length ? 'red lighten-5' : ''"
      >
        <v-text-field
          label="Orders per day"
          prepend-icon="mdi-calendar-today"
          hint="orders per day of the dishes"
          persistent-hint
          type="number"
          v-model="dish.maxOrderLimitPerOrder.content"
          :error-messages="dish.maxOrderLimitPerOrder.error"
          :error-count="dish.maxOrderLimitPerOrder.error.length"
        ></v-text-field>
      </v-container>

     <v-btn class="m-2" color="primary" @click="submit">Edit Dish</v-btn>
    </v-card>
  </v-container>
</template>

<script>
import Addon from "../../../../service/addon.service";
import Dish from "../../../../service/dish.service";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import InputValidator from "../../../../utility/inputValidator";
export default {
  name: "EditDish",
  data: function () {
    return {
      id: "",
      dish: {
        title: {
          content: "",
          error: [],
        },
        description: {
          content: "",
          error: [],
        },
        dishImage: {
          modal: false,
          rawImage: null,
          croppiedImage: null,
        },
        dishAddon: {
          addonModal: {
            state: false,
            update: false,
            availableAddon: [],
            editingAddon: "",
            value: {
              selectedAddon: {},
              price: 0,
              rule: [
                (value) =>
                  /^\d*(\.\d{0,2})?$/.test(value) ||
                  "Must be a number with 2 decimals",
              ],
            },
          },
          addonList: [],
          addonSubmitList: [],
        },
        availability: {
          content: [],
          error: [],
        },
        tags: {
          content: [],
          error: [],
        },
        price: {
          content: 0,
          error: [],
        },
        maxOrderLimitPerOrder: {
          content: 1,
          error: [],
        },
      },
      loading: {
        state: true,
        content: "",
      },
    };
  },
  components: {
    Cropper,
  },
  methods: {
    updateCropper: async function (inputValue) {
      const self = this;
      if (inputValue == null) {
        this.dish.dishImage.rawImage = "";
        return;
      }
      const reader = new FileReader();
      await reader.addEventListener("load", (e) => {
        self.dish.dishImage.rawImage = e.target.result;
      });
      // reader.addEventListener('error', e => this.previews[index] = this.errorImage);
      await reader.readAsDataURL(inputValue);
    },
    cropImage: async function (value) {
      if (!this.dish.dishImage.rawImage) {
        return;
      }
      const { canvas } = await this.$refs.cropper.getResult();
      console.log(canvas.toDataURL("image/webp"));
      this.dish.dishImage.croppiedImage = canvas.toDataURL("image/webp");
    },
    deleteImage: async function () {
      this.dish.dishImage.croppiedImage = "";
      this.dish.dishImage.rawImage = "";
    },
    updatePrice: async function () {
      const found = this.dish.dishAddon.addonModal.availableAddon.find(
        (addon) =>
          addon._id == this.dish.dishAddon.addonModal.value.selectedAddon
      );
      this.dish.dishAddon.addonModal.value.price = found.price;
    },
    addAddonToDish: async function () {
      const { selectedAddon, price } = this.dish.dishAddon.addonModal.value;

      const addonFound = this.dish.dishAddon.addonList.find(
        (addonInfo) => addonInfo.addon._id == selectedAddon
      );

      const addonObject = this.dish.dishAddon.addonModal.availableAddon.find(
        (addon) => addon._id == selectedAddon
      );
      if (addonFound) {
        addonFound.price = price;
      } else {
        this.dish.dishAddon.addonList.push({ addon: addonObject, price });
      }

      this.dish.dishAddon.addonSubmitList = this.dish.dishAddon.addonList.map(
        (e) => {
          return {
            addon: e.addon._id,
            price: e.price,
          };
        }
      );
      this.clearModal();
    },
    clearModal: function () {
      this.dish.dishAddon.addonModal.editingAddon = "";
      this.dish.dishAddon.addonModal.value.selectedAddon = null;
      this.dish.dishAddon.addonModal.value.price = "";
      this.dish.dishAddon.addonModal.state = false;
      this.dish.dishAddon.addonModal.update = false;
    },
    onlyForCurrency: async function (e) {
      var price = (
        this.dish.dishAddon.addonModal.value.price + e.key
      ).toString();
      if (e.key != ".") {
        if (isNaN(parseInt(e.key))) {
          e.preventDefault();
        }
      }
      if (price.split(".").length > 1) {
        if (e.key == "." && price.split(".").length == 0) {
          price += "0";
        }
        if (price.split(".")[1].length > 2) {
          e.preventDefault();
        }
      }

      if (isNaN(parseFloat(price))) {
        e.preventDefault();
      }
    },
    getAllAddon: function () {
      var availableAddons = this.dish.dishAddon.addonModal.availableAddon;
      var filtered = availableAddons.filter((availableAddon) => {
        if (this.dish.dishAddon.addonModal.editingAddon == availableAddon._id) {
          return true;
        }
        const found = this.dish.dishAddon.addonList.find((selectedAddon) => {
          return selectedAddon.addon._id == availableAddon._id;
        });

        if (found) {
          return false;
        }
        return true;
      });
      return filtered;
    },
    removeSelectedAddon: function (id) {
      this.dish.dishAddon.addonList = this.dish.dishAddon.addonList.filter(
        (addonInfo) => {
          return addonInfo.addon._id != id;
        }
      );
    },
    setLoadingState: function (state, content) {
      this.loading.state = state;
      this.loading.content = content;
    },
    editAddon: function (id) {
      const found = this.dish.dishAddon.addonList.find((addonInfo) => {
        return addonInfo.addon._id == id;
      });
      this.dish.dishAddon.addonModal.editAddon = found.addon._id;
      this.dish.dishAddon.addonModal.state = true;
      this.dish.dishAddon.addonModal.update = true;
      this.dish.dishAddon.addonModal.value.selectedAddon = found.addon._id;
      this.dish.dishAddon.addonModal.value.price = found.price;
    },
    submit: async function () {
      this.cropImage();
      const input = {
        title: this.dish.title.content,
        image: this.dish.dishImage.croppiedImage,
        description: this.dish.description.content,
        tags: this.dish.tags.content,
        availability: this.dish.availability.content,
        addonSelection: this.dish.dishAddon.addonSubmitList,
        price: parseFloat(this.dish.price.content),
        maxOrderLimitPerOrder: parseInt(this.dish.maxOrderLimitPerOrder.content),
      };
      this.setLoadingState(true, "Updating dish information");

      const dish = new Dish();
      InputValidator.clearError(this.dish);
      try {
        const result = await dish.updateDish(this.$route.params.id, input);

        const { content } = result;
        this.setLoadingState(false, "");

        await this.$store.dispatch("ModalModule/success", {
          title: `Dish has been updated`,
          content: `The dish  ${content.title} has been updated`,
          buttonContent: "Ok",
        });
        this.$router.push(`/dish/${content._id}`);
      } catch ({ content }) {
        // document.querySelector("")
        this.setLoadingState(true, "There's invalid input in the dish info");
        const self = this;
        setTimeout(() => {
          self.setLoadingState(false, "");
        }, 1000);
        InputValidator.mergeError(this.dish, content);
        console.log(content);
      }
    },
    remove: async function () {
      if (!confirm("Are you sure you want to delete such dish ?")) {
        return;
      }
      const dish = new Dish();
      try {
        const result = await dish.deleteDish(this.$route.params.id);
        this.$store.dispatch("ModalModule/success", {
          title: "Dish removed",
          content: "dish remove successfully",
          buttonContent: "OK",
        });
        this.$router.push("/");
      } catch (error) {}
    },
  },
  async mounted() {
    try {
      this.setLoadingState(true, "Loading dish information");
      const dish = new Dish();
      var dishDetail = await dish.getDish(this.$route.params.id);

      this.dish.title.content = dishDetail.content.title;
      this.dish.dishImage.croppiedImage = dishDetail.content.image
        ? dishDetail.content.image
        : "";
      this.id = dishDetail.content._id;
      this.dish.description.content = dishDetail.content.description;
      this.dish.tags.content = dishDetail.content.tags;
      this.dish.availability.content = dishDetail.content.availability;
      this.dish.dishAddon.addonSubmitList = dishDetail.content.addonSelection;
      this.dish.price.content = dishDetail.content.price;
      this.dish.maxOrderLimitPerOrder.content =
        dishDetail.content.maxOrderLimitPerOrder;

      const addon = new Addon();
      const result = await addon.getListOfAddon();
      const { content } = result;
      this.dish.dishAddon.addonModal.availableAddon = content;
      this.setLoadingState(false, "");
    } catch (error) {
      this.setLoadingState(true, "Unable to get dish info");
      const self = this;
      this.$nextTick(() => {
        var targetDiv = document.querySelector(".error--text").offsetTop;
        window.scrollTo({ top: targetDiv, behavior: "smooth" });
      });
      setTimeout(() => {
        self.setLoadingState(false, "");
        self.$router.push("/");
      }, 1000);
      console.log(error);
    }
  },
};
</script>

<style scoped>
.cropper {
  width: 280px;
  height: 180px;
}
.p-absolute {
  position: absolute;
}
</style>