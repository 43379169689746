<template>
  <div class="">
    <v-app-bar text app dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn @click="goHome" text>
        <v-icon>mdi-bowl-mix</v-icon>
        <span class="mx-2">Smart Canteen</span>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" class="dark">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content v-if="userInfo">
            <v-list-item-title>{{ userInfo.username }}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content v-if="!userInfo">
            <v-list-item-subtitle>
              <v-btn @click="drawer = false" to="/login"
                >Log in now</v-btn
              ></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          v-for="item in navigation"
          :key="item.title"
          :to="item.path"
          link
          class="dark"
          @click="drawer = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span
                class="mx-2"
                v-if="item.title == 'Cart' && cart"
              >
                <v-badge inline :content="cart.dishs.length">
                  {{ item.title }}
                </v-badge>
              </span>
              <span v-else>{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "mobileNavigation",
  data: function () {
    return {
      navigation: this.navigationItem,
      drawer: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
  props: {
    navigationItem: { type: Array, default: () => ({}) },
    userInfo: { type: Object, default: () => ({}) },
    cart: { type: Object, default: () => (null) },
  },
  watch: {
    navigationItem: function (val) {
      this.navigation = this.navigationItem;
    },
  },
};
</script>