<template>
  <v-container>
    <v-card
      class="mx-auto d-flex"
      fluid
      :class="$store.state.MiscModule.isMobile ? 'flex-column' : 'flex-row '"
    >
      <v-container
        class="border rounded"
        :style="$store.state.MiscModule.isMobile ? 'width:100%' : 'width:25%'"
        permanent
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Product Filter
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ filters.dishName.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                filters.dishName.title
              }}</v-list-item-title>
              <v-text-field
                label="Name"
                v-model="filters.dishName.content"
                :placeholder="filters.dishName.title"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ filters.containTags.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                filters.containTags.title
              }}</v-list-item-title>

              <v-combobox
                :label="filters.containTags.title"
                v-model="filters.containTags.content"
                @change="checkTags"
                multiple
                chips
              ></v-combobox>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ filters.priceRange.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                filters.priceRange.title
              }}</v-list-item-title>
              <v-text-field
                v-model="filters.priceRange.min.content"
                type="number"
                suffix="MYR"
                :label="filters.priceRange.min.title"
              ></v-text-field>
              <v-text-field
                v-model="filters.priceRange.max.content"
                type="number"
                suffix="MYR"
                :label="filters.priceRange.max.title"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="canHaveHidden">
            <v-list-item-icon>
              <v-icon>{{ filters.hidden.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ filters.hidden.title }}</v-list-item-title>
              <v-checkbox v-model="filters.hidden.content" label="Find Hidden Dish"></v-checkbox>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-btn color="primary" @click="search" block>
              <v-icon>mdi-magnify</v-icon> Search</v-btn
            >
          </v-list-item>
        </v-list>
      </v-container>
      <v-container
        :style="$store.state.MiscModule.isMobile ? 'width:100%' : 'width:75%'"
      >
        <h1 v-if="!loading && items.length == 0">
          Not Result for "{{ filters.dishName.content }}"
        </h1>
        <ListDish v-if="!loading && items" v-bind:items="items"></ListDish>
        <ListDishLoader v-else v-bind:items="pagination.limit"></ListDishLoader>

        <div class="text-center">
          <v-pagination
            v-if="this.items.length != 0"
            v-model="pagination.page"
            :length="pagination.totalPages"
            @input="search"
          ></v-pagination>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ListDish from "./partial/ListDish";
import ListDishLoader from "./partial/ListDishLoader";
import Dish from "../../service/dish.service";
export default {
  name: "Dish",
  data: function () {
    return {
      filters: {
        dishName: {
          title: "Dish Name",
          icon: "mdi-format-line-weight",
          content: "",
        },
        containTags: {
          title: "Tags",
          icon: "mdi-tag",
          content: [],
        },
        priceRange: {
          title: "Price Range",
          icon: "mdi-cash",
          min: {
            title: "Min Price",
            content: null,
          },
          max: {
            title: "Max Price",
            content: null,
          },
        },
        hidden: {
          title: "Hidden",
          icon: "mdi-eye",
          content: false,
        },
      },
      items: [],
      loading: true,
      canHaveHidden: false,
      pagination: {
        totalPages: 0,
        limit: 10,
        page: 1,
      },
    };
  },
  components: {
    ListDish,
    ListDishLoader,
  },
  methods: {
    checkTags: function (params) {
      console.log(this);
      this.filters.containTags.content = this.filters.containTags.content
        .map((e) => {
          return e.trim();
        })
        .filter((e) => e != "");
    },
    search: async function () {
      const dish = new Dish();
      try {
        this.loading = true;
        var result = null;
        if (this.canHaveHidden && this.filters.hidden.content) {
          result = await dish.searchAdminDish(
            this.filters.dishName.content,
            this.filters.containTags.content,
            {
              min: this.filters.priceRange.min.content,
              max: this.filters.priceRange.max.content,
            },
            this.pagination.page,
            this.pagination.limit,
            this.filters.hidden.content
          );
        } else {
          result = await dish.searchDish(
            this.filters.dishName.content,
            this.filters.containTags.content,
            {
              min: this.filters.priceRange.min.content,
              max: this.filters.priceRange.max.content,
            },
            this.pagination.page,
            this.pagination.limit
          );
        }

        const { content } = result;
        this.items = content.hits;
        this.pagination.totalPages = content.totalPages;
        if (this.pagination.page > this.pagination.totalPages) {
          this.pagination.page = this.pagination.totalPages;
          this.search();
        } else {
          this.pagination.page = 1;
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  mounted: async function () {
    const result = await this.$store.getters["UserModule/getUser"];
      console.log(this.user);

    this.search();
  },
  asyncComputed: {
    user: {
      async get() {
        const result = await this.$store.getters["UserModule/getUser"];
            if (result != null) {
      if (result.accountType == "admin") {
        this.canHaveHidden = true;
      }
    }
        return result;
      },
      default: null,
    },
  },
};
</script>

<style>
</style>