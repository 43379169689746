import axios from '../helper/axiosWithHeader'


export default class Promotion {
    
    async insertPromotion(name, coupon, dishs, hidden) {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/promotion`, {
            name, coupon, dishs, hidden
        });
    }

    async updatePromotion(name, coupon, dishs, hidden, id) {
        return await axios.put(`${process.env.VUE_APP_ROOT_API}/promotion/${id}`, {
            name, coupon, dishs, hidden
        });
    }

    async getAllPromotion() {
        const promotions = await axios.get(`${process.env.VUE_APP_ROOT_API}/promotion`);
        return promotions;
    }



    async getAllPromotionAdminSearch(name, hidden, limit, page) {
        const promotions = await axios.post(`${process.env.VUE_APP_ROOT_API}/promotion/search`, {
            name, hidden, limit, page
        });
        return promotions;
    }

    async findPromotionById(id) {
        const promotions = await axios.get(`${process.env.VUE_APP_ROOT_API}/promotion/${id}`);
        return promotions;
    }

    async delete(id) {
        return await axios.delete(`${process.env.VUE_APP_ROOT_API}/promotion/${id}`);
    }

    // async findPromotion(couponCode) {
    //     const coupons = await axios.get(`${process.env.VUE_APP_ROOT_API}/coupon/${couponCode}`);
    //     return coupons;
    // }




    // async getAllValidPromotionAdmin(couponCode) {
    //     const coupons = await axios.get(`${process.env.VUE_APP_ROOT_API}/coupon/valid`);
    //     return coupons;
    // }

 
}