import { userNotLogin, userLogin, userType } from "./guardFunction";

export function guard(store, router) 
{  
    router.beforeEach(async (to,from,next)=>{ 
        const rules = to.meta

        if (rules["requireNotLogin"] == true) 
        {
            const condition = await userNotLogin(store, to);
            if (!condition) {
                next({name: "Home"})
                return;
            }
        } 
        if (rules["requireLogin"] == true) 
        {
            const condition = await userLogin(store, to);

            if (!condition) {
                next({path: "Login"})
                return;
            }
        }
        if (rules["userType"] != undefined) 
        {
            const condition = await userType(store, to, rules["userType"]);

            if (!condition) {
                next({name: "Home"})
                return;
            }
        }

        
        next();
    })
}