<template>
  <v-container>
    <div class="row mx-auto">
      <div class="col-md-4 col-sm-6 col-lg-3" v-for="item in items" :key="item.id">
        <DishItem class="cardSize" max-width="300px" max-height="217px" min-height="217px" :item="item"></DishItem>
      </div>
    </div>
  </v-container>
</template>

<script>
import DishItem from "./Dish/DishItem";
export default {
  name: "ListDish",
  props: ["items"],
  components: {
    DishItem,
  },
};
</script>

<style>
  .cardSize {
    max-width: 300px;
    max-height: 217px;
    min-height: 217px;
  }
</style>