<template>
  <v-container v-if="dish">
    <div class="mt-5 mx-auto w-80">
      <v-btn color="primary" @click="$router.push({name : 'Home'})"
        ><v-icon>mdi-arrow-left</v-icon> Back</v-btn
      >
    </div>

    <v-card :class="($store.state.MiscModule.isMobile ? '' : 'w-80') + 'mt-5 mx-auto'">
      <v-img
        class="white--text align-end text-center"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        max-height="300"
        :src="dish.image ? dish.image : `../../assets/placeholder.png`"
        lazy-src="../../assets/placeholder.png"
      >
        <h2 class="display-3 z-index">{{ dish.title }}</h2>
      </v-img>
      <v-container class="bg-primary text-white">
        <h3 class="text-center">Price : {{ dish.price }} MYR</h3>
      </v-container>
      <v-container class="text-left" v-if="user != null">
        <v-btn
          v-if="user.accountType == 'admin'"
          color="orange"
          class="text-white mr-2"
          @click="$router.push(`/dish/edit/${dish._id}`)"
          ><v-icon class="mr-2">mdi-cog</v-icon> Edit</v-btn
        >
        <v-btn
          v-if="user.accountType == 'admin'"
          :color="dish.unlist ? 'green' : 'red'"
          class="text-white mr-2"
          @click="dishListToggle()"
        >
          <v-icon class="mr-2">{{
            dish.unlist ? "mdi-eye" : "mdi-eye-off"
          }}</v-icon>
          {{ dish.unlist ? "List" : "Unlist" }}
        </v-btn>
        <v-btn color="green" class="text-white" @click="cartModal.state = true"
          ><v-icon class="mr-2">mdi-cart-arrow-down</v-icon> Add to Cart</v-btn
        >
      </v-container>

      <v-container class="text-left" v-else>
        <v-btn @click="askUserToLogin()" color="green" class="text-white"
          ><v-icon class="mr-2">mdi-cart-arrow-down</v-icon> Add to Cart</v-btn
        >
      </v-container>

      <v-container class="d-flex">
        <p class="mr-2 pt-1">Tags</p>
        <div class="">
          <v-chip
            class="mr-2"
            v-for="tag in dish.tags"
            :key="tag"
            text-color="white"
            :color="obtainRandomColor()"
            >{{ tag }}</v-chip
          >
        </div>
      </v-container>
      <v-container class="d-flex">
        <p class="mr-2 pt-1">Available On</p>
        <div class="">
          <v-chip
            class="mr-2"
            v-for="availability in dish.availability"
            :key="availability"
            text-color="white"
            color="primary"
            >{{ availability }}</v-chip
          >
        </div>
      </v-container>

      <v-container class="d-flex">
        <p class="mr-2 pt-1">Available Addons</p>
        <div class="">
          <v-chip
            class="mr-2"
            v-for="addon in dish.addonSelection"
            :key="addon._id"
            text-color="white"
            :color="obtainRandomColor()"
            >{{ addon.addon.name }} - {{ addon.price }} MYR</v-chip
          >
        </div>
      </v-container>

      <v-divider></v-divider>
      <h2 class="text-center">Description</h2>
      <v-container class="blue-grey lighten-5 px-3">
        <p>{{ dish.description }}</p>
      </v-container>
    </v-card>

    <DishModal :dish="dish" :dishModal="cartModal"></DishModal>
  </v-container>
</template>

<script>
import Dish from "../../service/dish.service";
import DishModal from "../dishes/partial/DishModal";
export default {
  name: "ViewDish",
  data() {
    return {
      dish: null,
      cartModal: {
        state: false,
        data: {
          addon: [],
          quantity: 1,
          remarks: "",
        },
      },
    };
  },
  components: {
    DishModal,
  },
  methods: {
    obtainRandomColor: function () {
      const color = ["primary", "warning", "secondary", "red", "green"];
      return color[Math.floor(Math.random() * color.length)];
    },
    askUserToLogin: function () {
      this.$store.dispatch("ModalModule/error", {
        title: "Error",
        content: "Please Login to for this functionality",
        buttonContent: "OK",
      });
      this.$router.push("/login");
    },
    dishListToggle: async function () {
      const dish = new Dish();
      const result = await dish.toggleDishList(
        this.$route.params.id,
        !this.dish.unlist
      );
      const { content } = result;
      this.$store.dispatch("ModalModule/success", {
          title: "Dish list type have been updated",
          content: `dish has been ${!content.unlist? 'made public' : 'hidden'}`,
          buttonContent: "OK",
      });
      this.dish.unlist = content.unlist;
    },
  },
  async created() {
    try {
      const dish = new Dish();
      const result = await dish.getDish(this.$route.params.id);
      const { content } = result;

      this.dish = content;
      this.dish.addonSelection = this.dish.addonSelection.filter(addonData => {
        return !addonData.addon.hidden;
      });
    } catch (error) {
      console.log(error);
    }
  },
  asyncComputed: {
    user: {
      async get() {
        const result = await this.$store.getters["UserModule/getUser"];
        return result;
      },
      default: null,
    },
  },
};
</script>

<style scoped>
.w70p {
  width: 70%;
}
</style>