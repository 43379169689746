import axios from '../helper/axiosWithHeader'
import Cookie from '../utility/cookie'

export default class Auth {
    static async authenticate(email, password) {
        try {
            const result = await axios.post(`${process.env.VUE_APP_ROOT_API}/auth`, {
                email : email,
                password: password
            });
            const {token, user} = result.content
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            return {token, ...user}
        } catch (error) {
            return error         
        }
    }

    static async signup(username, email, password, conpassword) {
            const result = await axios.post(`${process.env.VUE_APP_ROOT_API}/auth/signup`, {
                username: username,
                email : email,
                password: password,
                conpassword: conpassword
            });
            return result;
    }

    static async isResultContainError(requestResult) {
        if (requestResult.error == true) {
            return true;
        }
        return false;
    }

    static async isAuthenticated() {
        if(Cookie.isset("user")) {
            return this.isAuthValid()
        }
        return false;
    }

    static async isAuthValid() {
        return (this.getUser() == null)? false : true;
    }

    static async getUser() {
        try {
            var result = await axios.get(`${process.env.VUE_APP_ROOT_API}/auth/`)
            const {token, user} = result.content
            return {token, ...user}
        } catch (error) {
            return error          
        }
    }




}