import axios from "../helper/axiosWithHeader";

export default class Addon {
    async getListOfAddon() {
        const result = await axios.get(`${process.env.VUE_APP_ROOT_API}/addon/`);
        return result
    }
    async getAddon(id) {
        const result = await axios.get(`${process.env.VUE_APP_ROOT_API}/addon/${id}`);
        return result
    }
    async getAddonByName(name) {
        const result = await axios.get(`${process.env.VUE_APP_ROOT_API}/addon/name`, {
            name
        });
        return result
    }

    async createAddon(name, price) {
        const result = await axios.post(`${process.env.VUE_APP_ROOT_API}/addon/`, {
            name, price
        })
        return result
    }

    async editAddon(id, name, price) {
        const result = await axios.put(`${process.env.VUE_APP_ROOT_API}/addon/${id}`, {
            name, price
        })
        
        return result
    }

    async deleteAddon(id) {
        const result = await axios.delete(`${process.env.VUE_APP_ROOT_API}/addon/${id}`)
        return result
    }


}