<template>
  <v-container>
      <h1 class="display-4 mx-auto text-center mt-5">An Error has occurred {{ code || ''}}</h1>
      <p class=" mx-auto text-center mt-5">An error has occurred, and we are not sure where to bring you</p>

      <v-btn color="primary" block to="/">Get me out of here</v-btn>
  </v-container>
</template>

<script>
export default {
    name: "Error"
}
</script>

<style>

</style>