import Cookie from "../../../utility/cookie";

const getUserDetail = async (store, to) => {
    if (Cookie.findCookie("user") == "null" || Cookie.findCookie("user") == "") {
        return null        
    }
    await store.dispatch("UserModule/getUserInfoNotCached");
    const user = await store.getters['UserModule/getUser'];
    return user;
}

export const userNotLogin = async function(store, to){
    const user = await getUserDetail(store, to)
    if (!user) {
       return true;
    }
    return false
}

export const userLogin = async function(store, to){
    const user = await getUserDetail(store, to)
    if (user) {
        return true;
    }
    return false
}

export const userType = async function(store, to, type){
    const user = await getUserDetail(store, to)
    if (!user) return false;
    if (type.constructor === Array) {
        if (type.indexOf(user.accountType) == -1) {
            return false;
        }
        return true;
    }
    if (user.accountType == type) {
        return true;
    }
    return false
}