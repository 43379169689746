<template>
  <v-container fluid>
    <v-card fluid class="mx-auto p-3 mb-3">
      <h3>Purchased Product</h3>
      <v-list three-line v-if="order">
        <template v-for="(orderItem, index) in order.dishs">
          <CartListItem :cartItem="orderItem" :key="orderItem._id">
          </CartListItem>
          <v-divider
            class="m-0"
            :key="index"
            v-if="index < order.dishs.length - 1"
          ></v-divider>
        </template>
      </v-list>
    </v-card>

    <v-stepper alt-label class="mb-3">
      <v-stepper-header>
        <v-stepper-step :complete="getStep() >= 1" step="1"> Order Started </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="getStep() >= 2" step="2"> Paid </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="getStep() >= 3" step="3"> Prepare on {{ moment(order.preparation.date, order.preparation.batch.hour) }} 
           </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="getStep() >= 4" step="4"> Collected </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <v-card class="mx-auto p-3">
      <h3 class="text-left">Payment Information</h3>
      <p>Total Price Of Dish : {{ order.total }} MYR</p>
      <p>Payment Type : {{ order.stripePayment.paymentMethod }}</p>
      <p v-if="order.total != order.stripePayment.paid_amount">
        Discounts :
        {{
          (
            order.total - (order.stripePayment.paid_amount / 100 || order.total)
          ).toFixed(2)
        }}
        MYR
      </p>
      <p v-else>Discounts : 0 MYR</p>
      <p>
        Paid Price :
        {{ order.stripePayment.paid_amount / 100 || order.total }} MYR
      </p>
      <p>Order Placed on : {{moment(order.created)}}</p>
      <p>Paid on : {{moment(order.stripePayment.paid_on)}}</p>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import CartListItem from "../user/partial/CartListItem";

export default {
  name: "Receipt",
  props: ["order"],
  components: {
    CartListItem,
  },
  methods: {
    moment: function(date, hour){ 
      if (hour == null) {
      return moment(date).format("DD MMMM YYYY, h:mm a");
      }
      return moment(date).set("hour", hour).format("DD MMMM YYYY, h:mm a");
    },
    getStep: function () {
      if (this.order.isCollected) {
        return 4;
      }
      if (this.order.isCompleted) {
        return 3;
      }
      if (this.order.isPaid) {
        return 2;
      }
      return 1;
    },
  },
};
</script>

<style>
</style>