<template>
  <v-container class="w-50">
    <v-container
      class=""
      :class="
        $store.state.MiscModule.isMobile ? '' : 'd-flex justify-content-between'
      "
    >
      <v-btn-toggle
        class="mt-2"
        :style="
          $store.state.MiscModule.isMobile
            ? 'flex-direction: column; width:100%'
            : ''
        "
        v-model="salesType"
        mandatory
      >
        <v-btn color="primary"> Year </v-btn>
        <v-btn color="primary"> Month </v-btn>
      </v-btn-toggle>

    </v-container>
    <h3 class="text-center">Sales in a {{ (salesType == 0)? "Year " + moment() : "Month " + moment("M") }}</h3>
    <LineChart :width="100" :chartData="data" :options="option"></LineChart>
  </v-container>
</template>

<script>
import LineChart from "./chartjs/LineChart.vue";
import Analysis from "../../../service/analysis.service";
import moment from "moment";
export default {
  name: "Sales",
  data: function () {
    return {
      data: null,
      option: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          tooltip: {
            position: "bottom",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      salesType: null,
    };
  },
  components: {
    LineChart,
  },
  methods: {
    moment: (type = "YYYY") => moment().format(type),
    getSales: async function (type = "month") {
      const analysis = new Analysis();
      const result = await analysis.getSales(type);
      console.log(result);

      this.data = result.content;
      this.showThisMonth(type);
    },
    showThisMonth: function(type = "month"){
      this.data.datasets.forEach(function (ds, index) {
        if (type == "month") {
          if (Math.ceil(moment().date() / 7) != index) {
            ds.hidden = true;
          }
        } else {
          if (moment().format("M") != index + 1) {
            ds.hidden = true;
          }
        }
      });
    },
    hideAll: function () {
      this.data.datasets.forEach(function (ds, index) {
        ds.hidden = true;
      });
    },
    showAll: function () {
      this.data.datasets.forEach(function (ds, index) {
        ds.hidden = false;
      });
    },
  },
  mounted: function () {
    this.$nextTick((e) => {
      this.salesType = 0;
    });
  },
  watch: {
    salesType: function (type) {
      if (type) {
        this.getSales("month");
      } else {
        this.getSales("year");
      }
    },
  },
};
</script>

<style>
</style>