<template>
  <v-tab-item>
    <v-card flat>
      <v-card-text>


        <v-container>
          <v-list>
            <Order v-for="(orderItem, orderIndex) in orders" :order="orderItem" v-bind:key="orderIndex"></Order>
          </v-list>
        </v-container>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import Order from './Order'
export default {
    data: function () {
        return {
            availableSortingFeature: ['View Last 30 Days Order','View Last 30 Days Canceled Order'],
        }
    },
    props: ["orders"],
    components: {
      Order
    }
};
</script>
