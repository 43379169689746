<template>
  <div class="">
    <v-container
      background-color="white"
      style="width: 75%"
      border
      rounded
      class="mt-10"
      tile
    >
      <div
        :class="$store.state.MiscModule.isMobile ? '' : 'd-flex'"
        v-if="cart.dishs.length"
      >
        <div class="" style="width: 100%">
          <h3 class="text-muted">Cart Item</h3>
          <v-divider></v-divider>
          <v-list three-line>
            <template v-for="(cartItem, index) in cart.dishs">
              <CartListItem :cartItem="cartItem" :key="cartItem._id">
              </CartListItem>
              <v-divider
                class="m-0"
                :key="index"
                v-if="index < cart.dishs.length - 1"
              ></v-divider>
            </template>
          </v-list>
        </div>
        <div class="pl-3" style="width: 100%">
          <h3 class="text-muted">Total</h3>
          <ul>
            <li
              class="d-flex justify-content-between"
              v-for="(cartItem, index) in cart.dishs"
              :key="cartItem._key"
            >
              <p>
                Item {{ index + 1 }} : {{ cartItem.dish.title }} (Price :
                {{ cartItem.dish.price }}) X {{ cartItem.quantity }} =
                <b>{{ cartItem.total }}</b>
              </p>
              <div class="btn-group" elevation="2">
                <v-btn
                  small
                  color="warning"
                  tile
                  elevation="0"
                  @click="
                    loadModal(
                      cartItem.dish._id,
                      cartItem.addons,
                      cartItem.quantity,
                      cartItem.remarks
                    )
                  "
                  ><v-icon class="white--text">mdi-cog</v-icon></v-btn
                >
                <v-btn
                  small
                  color="red"
                  tile
                  elevation="0"
                  class="white--text"
                  @click="removeDish(cartItem.dish._id)"
                  ><v-icon class="white--text">mdi-trash-can</v-icon></v-btn
                >
              </div>
            </li>
          </ul>
          <v-divider></v-divider>
          <h2>Total: {{ finalCartItemPrice() }}</h2>
          <v-divider></v-divider>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="checkout.orderDate"
                label="Please select an preparation date"
                :messages="
                  allowedDaysArray.length
                    ? 'Avaliable on ' + allowedDaysArray.join(', ')
                    : 'There\'s no date avaliable, because not all dish are avaliable on the same day'
                "
                :error-messages="
                  allowedDaysArray.length
                    ? ''
                    : 'There\'s no date avaliable, because not all dish are avaliable on the same day'
                "
                :persistent-hint="true"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="checkout.orderDate"
              @input="menu = false"
              :allowed-dates="allowedDays"
              :min="tomorrowDate"
              @update:picker-date="pickerUpdate($event)"
            ></v-date-picker>
          </v-menu>
          <v-select
            v-model="checkout.batch"
            prepend-icon="mdi-clock-time-eight-outline"
            :items="batches"
            item-text="name"
            item-value="_id"
            label="Select"
            persistent-hint
            return-object
            single-line
          ></v-select>
          <v-text-field
            prepend-icon="mdi-tag"
            label="Please enter a discount code (you can add your coupon during checkout)"
            disabled
          ></v-text-field>
          <v-btn
            :disabled="allowedDaysArray.length == 0"
            @click="createOrder()"
            class="primary float-right"
            >Checkout</v-btn
          >
        </div>
      </div>
      <v-container class="text-center" v-else>
        <h1 class="text-muted">Empty Cart</h1>
        <p>Your cart is currently empty, you may add more item by going here</p>
        <v-btn
          color="primary"
          class="mt-3"
          @click="$router.push({ name: 'Home' })"
          >Go Add Some Dish</v-btn
        >
      </v-container>
      <DishModal
        :dish="modalDish"
        :dishModal="cartModal"
        @syncCart="syncCart()"
        :update="true"
      ></DishModal>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import Dish from "../../service/dish.service";
import Batch from "../../service/batch.service";
import CartListItem from "./partial/CartListItem";
import DishModal from "../dishes/partial/DishModal";
export default {
  name: "Cart",
  data: function () {
    return {
      cart: { dishs: [] },
      menu: false,
      modalDish: null,
      cartModal: {
        state: false,
        data: {
          addon: [],
          quantity: 1,
          remarks: "",
        },
      },
      checkout: {
        orderDate: "",
        batch: null,
        discount: "",
      },
      batches: [],
      tomorrowDate: moment().add(1, "days").format(),
      allowedDate: [],
      allowedDaysArray: [],
    };
  },
  components: {
    CartListItem,
    DishModal,
  },
  methods: {
    loadModal: async function (dishId, addons, quantity, remarks) {
      try {
        const dish = new Dish();
        const result = await dish.getDish(dishId);
        const { content } = result;
        content.addonSelection = content.addonSelection.filter(
          (addonData) => {
            return !addonData.addon.hidden;
          }
        );
        this.modalDish = content;
        this.cartModal.state = true;
        this.cartModal.data.addon = addons.map(
          (addonItem) => addonItem.addon._id
        );
        this.cartModal.data.quantity = quantity;
        this.cartModal.data.remarks = remarks;
      } catch (error) {
        console.log(error);
      }
    },
    finalCartItemPrice: function () {
      if (!this.cart.dishs.length) {
        return 0;
      }
      var total = this.cart.dishs.map((cartItem) => {
        return parseFloat(cartItem.total);
      });
      total = total.reduce((sum, acc) => {
        return sum + acc;
      });
      return total.toFixed(2);
    },
    allowedDays: function (date) {
      console.log({ date, allowedDate: this.allowedDate });
      const found = this.allowedDate.find((adate) => {
        return adate == date;
      });
      return found ? true : false;
    },
    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();
      let currentMonth = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day = 1;
      if (currentMonth == monthSelected) day = moment().format("D");
      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(monthSelected - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (this.allowedDaysArray.indexOf(moment(date).format("dddd")) != -1)
          this.allowedDate.push(date);
      }
      this.allowedDays();
    },
    getBatches: async function () {
      const batch = new Batch();
      const result = await batch.getBatches();
      this.batches = result.content;
      this.batches = this.batches.map((batch) => {
        batch.name = `${batch.name} - ${Batch.hourFormat(batch.hour)}`;
        return batch;
      });
    },
    createOrder: function (val) {
      var query = {};
      if (this.checkout.orderDate) query["orderDate"] = this.checkout.orderDate;
      if (this.checkout.batch) query["batch"] = this.checkout.batch._id;
      if (this.checkout.discount) query["discount"] = this.checkout.discount;
      this.$router.push({
        name: "Create Order",
        query,
      });
    },
    removeDish: async function (id) {
      console.log({ id });
      await this.$store.dispatch("CartModule/removeDishCart", id);
      this.syncCart();
    },
    syncCart: async function () {
      await this.$store.dispatch("CartModule/getCart");
      const cart = await this.$store.getters["CartModule/getCart"];
      this.allowedDaysArray = cart.possibleOrderDays;
      this.cart = cart;
    },
  },
  mounted: async function () {
    this.syncCart();
    this.getBatches();
  },
};
</script>

<style>
</style>
