<template>
  <v-container>
    <v-card>
      <h1 class="text-center my-3">List User</h1>
      <v-divider></v-divider>
      <p class="text-center">
        There's currently {{ hitList.userCountInDB }} accounts in the website
      </p>

      <v-container>
        <div>
          <v-text-field
            prepend-icon="mdi-account"
            label="Search by Username"
            v-model="searchTerm.username"
          ></v-text-field>

          <v-select
            prepend-icon="mdi-account-group"
            :items="typeOfAccount"
            clearable
            label="Account Type"
            v-model="searchTerm.accountType"
          ></v-select>

          <v-btn block color="primary" class="mt-2 mr-4" @click="search()">
            <v-icon class="mr-2">mdi-magnify</v-icon>
            Search
          </v-btn>
        </div>
      </v-container>

      <v-container v-if="!loading">
        <div v-if="hitList.hits.length == 0">
            <h1 class="text-center text-muted">No Result</h1>
            <small class="d-block text-center">Try changing the search term</small>
        </div>
        <v-simple-table  v-if="hitList.hits.length != 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Username</th>
                <th class="text-left">Email</th>
                <th class="text-left">Account Type</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in hitList.hits" :key="user._id">
                <td>{{ user.username }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.accountType }}</td>
                <td><v-btn :to="`/worker/user/${user._id}`">View Profile</v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-center">
          <v-pagination
            v-if="hitList.hits.length != 0"
            v-model="pagination.page"
            :length="pagination.totalPages"
            @input="search"
          ></v-pagination>
        </div>
      </v-container>
      <v-container class="d-flex justify-content-center" v-if="loading">
        <div class="">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Worker from "../../../../service/worker.service";
export default {
  name: "ListUser",
  data: function () {
    return {
      loading: false,
      hitList: {
        userCountInDB: 0,
        userCountInFilteredList: 0,
        hits: [],
      },
      pagination: {
        totalPages: 0,
        limit: 5,
        page: 1,
      },
      searchTerm: {
        username: "",
        accountType: "",
      },
      typeOfAccount: ["admin", "worker","user"],
    };
  },
  methods: {
    search: async function () {
      const worker = new Worker();
      this.loading = true;
      var result = await worker.getUserList(
        this.searchTerm.username,
        this.searchTerm.accountType,
        this.pagination.page,
        this.pagination.limit
      );

      if (result.error) {
        return;
      }
      const { content } = result;
      this.hitList.hits = content.hits;
      this.hitList.userCountInDB = content.userCountInDB;
      this.hitList.userCountInFilteredList = content.userCountInFilteredList;
      this.pagination.totalPages = content.totalPages;

      if (this.pagination.page > this.pagination.totalPages) {
         this.pagination.page = this.pagination.totalPages;
         this.search(); 
      }

      this.loading = false;
    },
  },
  created() {
    this.search();
  },
};
</script>

<style>
</style>