import getNavigationRules from "../../configuration/navigation";

const NavigationModule = {
    namespaced: true,
    state: {
        navigationItem: []
    },
    getters: {
        getNavigationItem(state) {
            return state.navigationItem;
        }
    },
    mutations: {
        resetNavigation(state){
            state.navigationItem = getNavigationRules("unauthenticated")
        },

        setNavigationItem(state, type) {
            state.navigationItem = getNavigationRules(type)
        }

    },
    actions: {
        async setNavigation({commit}, type) {
            commit("setNavigationItem", type)
        },
    },
}

export default NavigationModule;