<template>
  <v-container >
    <v-card class="p-3">
      <v-btn color="primary" @click="$router.push({name: 'Home'})">Go Home</v-btn>
      <h1 class="text-center">Payment Successfull</h1>
      <Receipt :order="order">
      </Receipt>
    </v-card>
  </v-container>
</template>

<script>
import Order from "../../service/order.service";
import Receipt from "./Receipt";

export default {
  name: "PaymentSuccess",
  data: function () {
    return {
      order: null,
    };
  },
  methods: {
    async getOrder(id) {
      const order = new Order();
      try {
        const orderResult = await order.getOrder(id);
        this.order = orderResult.content;
      } catch (error) {
        console.log({ error });
      }
    },
  },
  components: {
    Receipt
  },
  mounted: function () {
    this.getOrder(this.$route.params.id);
  },
};
</script>

<style>
</style>