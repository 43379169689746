<template>
  <v-dialog v-model="batchModal.state" width="500">
    <v-card>
      <v-card-text>
        <v-card-title v-if="!update"> Add Batch </v-card-title>
        <v-card-title v-if="update"> Edit Batch </v-card-title>
        <v-container>
          <v-text-field
            label="Batch Name"
            v-model="batchModal.name.content"
            :error-messages="batchModal.name.error"
            :error-count="batchModal.name.error.length"
          ></v-text-field>

          <div class="">
            <label for=""
              >Batch Hour (Current Hour
              {{ hourFormat(batchModal.hour.content) }})</label
            >
            <v-slider
              max="17"
              min="7"
              v-model="batchModal.hour.content"
              :error-messages="batchModal.hour.error"
              :error-count="batchModal.hour.error.length"
            ></v-slider>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="batchModal.state = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="!update" text @click="addBatch()">
          Add Batch
        </v-btn>
        <v-btn color="primary" v-if="update" text @click="updateBatch()">
          Edit Batch
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputValidator from "../../../../../utility/inputValidator";
import Batch from "../../../../../service/batch.service";
export default {
  name: "BatchesModal",
  props: ["batchModal", "update"],
  methods: {
    addBatch: async function () {
      InputValidator.clearError(this.batchModal);

      const batch = new Batch();
      try {
        const { name, hour } = this.batchModal;
        const result = await batch.insertBatch(name.content, hour.content);
        name.content = "";
        hour.content = 7;

        this.$emit("loadBatch", "");
        this.batchModal.state = false;
      } catch (error) {
        this.batchModal.state = true;

        InputValidator.mergeError(this.batchModal, error.content);
        console.log(this.batchModal);
      }
    },
    updateBatch: async function () {
      InputValidator.clearError(this.batchModal);

      const batch = new Batch();
      try {
        const { name, hour } = this.batchModal;
        const result = await batch.updateBatch(this.batchModal.batch._id, name.content, hour.content);
        name.content = "";
        hour.content = 7;

        this.$emit("loadBatch", "");
        this.batchModal.state = false;
      } catch (error) {
        this.batchModal.state = true;

        InputValidator.mergeError(this.batchModal, error.content);
        console.log(this.batchModal);
      }
    },

    hourFormat: function (hour) {
      if (hour < 12) {
        return `${hour}:00am`;
      } else if (hour == 12) {
        return `${hour}:00pm`;
      }
      return `${hour - 12}:00pm`;
    },
  },
};
</script>

<style>
</style>