<template>
  <v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="mx-auto my-3 p-3">
      <v-btn
        class="float-right"
        color="primary"
        @click="$router.push({ name: 'Create Promotion' })"
        >Create new</v-btn
      >
      <h1>Promotion</h1>

      <v-container
        class=""
        :class="
          $store.state.MiscModule.isMobile
            ? ''
            : 'd-flex justify-content-between'
        "
      >
        <v-btn-toggle
          class="mt-2"
          :style="
            $store.state.MiscModule.isMobile
              ? 'flex-direction: column; width:100%'
              : ''
          "
          v-model="searchTerm.hidden"
          mandatory
        >
          <v-btn color="primary"> Shown </v-btn>
          <v-btn color="primary"> Hidden </v-btn>
        </v-btn-toggle>
      </v-container>

      <v-container class="d-flex">
        <v-text-field
          v-model="searchTerm.name"
          name="Name"
          label="Name"
          hint="Please enter the Name of the promotion"
        ></v-text-field>

        <v-btn color="primary" @click="search">Find</v-btn>
      </v-container>

      <v-container class="border">
        <v-list two-line>
          <template v-for="promotion in promotions">
            <div :key="promotion._id">
              <v-list-item>
                <template>
                  <v-list-item-content>
                    <div class="d-flex justify-content-between">
                      <div class="d-block w-100">
                        <p class="mr-3">Promotion : {{ promotion.name }}</p>
                        <p class="mr-3" v-if="promotion.coupon">
                          affiliated to coupon :
                          <code>{{ promotion.coupon.code }}</code>
                        </p>
                        <p class="mr-3">Contain</p>
                        <ul class="list-group">
                          <li
                            class="list-group-item py-2 d-flex"
                            v-for="dish in truncateList(promotion.dishs, 3)
                              .dishs"
                            :key="dish._id"
                          >
                            {{ dish.title }}
                          </li>
                          <li
                            class="list-group-item py-2"
                            v-if="truncateList(promotion.dishs, 3).truncated"
                          >
                            and
                            {{ truncateList(promotion.dishs, 3).truncated }}
                            more items
                          </li>
                        </ul>
                      </div>
                      <div class="d-flex">
                        <v-btn
                          color="primary"
                          @click="
                            $router.push({
                              name: 'Edit Promotion',
                              params: {
                                id: promotion._id,
                              },
                            })
                          "
                        >
                          Edit
                        </v-btn>
                        <v-btn
                          color="red"
                          class="white--text"
                          @click="deletePromotion(promotion._id)"
                          >Delete</v-btn
                        >
                      </div>
                    </div>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>
        </v-list>
      </v-container>

      <div class="text-center">
        <v-pagination
          v-if="this.pagination.totalPages > 1"
          v-model="searchTerm.page"
          :length="pagination.totalPages"
          @input="search"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Promotion from "../../../../service/promotion.service";
export default {
  name: "ListPromotion",
  data: function () {
    return {
      searchTerm: {
        hidden: false,
        name: "",
        page: 1,
      },
      pagination: {
        limit: 3,
        totalPages: 0,
      },
      promotions: [],
    };
  },
  methods: {
    search: async function () {
      const promotion = new Promotion();
      const { hidden, name, page } = this.searchTerm;
      const result = await promotion.getAllPromotionAdminSearch(
        name,
        hidden,
        this.pagination.limit,
        page
      );
      this.promotions = result.content.hits;
      this.pagination.totalPages = result.content.totalPages;
    },
    truncateList: function (dishs, limit) {
      var newList = dishs.filter((e, index) => {
        return index < limit;
      });
      return {
        dishs: newList,
        truncated: dishs.length - newList.length,
      };
    },
    deletePromotion: async function (id) {
      const promotionData = this.promotions.find(
        (promotion) => promotion._id == id
      );
      if (!promotionData) {
        return;
      }
      if (!confirm("Are you sure you want to delete")) {
        return;
      }

      const promotion = new Promotion();
      try {
        await promotion.delete(id);
        await this.$store.dispatch("ModalModule/success", {
          title: `Promotion has been removed`,
          content: `Promotion ${promotionData.name} has been removed`,
          buttonContent: "Ok",
        });
        this.search();
      } catch (error) {
        await this.$store.dispatch("ModalModule/error", {
          title: `Could not delete promotion`,
          content: `An error occurred when deleting ${promotionData.name}`,
          buttonContent: "Ok",
        });
      }
    },
  },
  mounted: function () {
    this.search();
  },
  watch: {
    "searchTerm.hidden": function (val) {
      console.log({ val });
      this.search();
    },
  },
};
</script>

<style>
</style>