import axios from "axios";

export default class Admin {


    async createWorker(username, email, password) {
            const result = await axios.post(`${process.env.VUE_APP_ROOT_API}/admin/create/worker`, {
                username, password, email
            });
            return result
    }


}