
import Profile from '@/components/user/Profile.vue'
import Cart from '@/components/user/Cart.vue'
import Logout from '@/components/user/Logout.vue'
import CreateOrder from '@/components/user/CreateOrder.vue'
import PaymentSuccess from '@/components/payment/Success.vue'
import PaymentCancel from '@/components/payment/Cancel.vue'
import Receipt from '@/components/payment/ViewReceipt.vue'
import ChangePassword from '../../components/user/ChangePassword.vue'
import ForgetPassword from '../../components/user/ForgetPassword.vue'
import ResetPassword from '../../components/user/ResetPassword.vue'
export const UserRouter = [
      {
        path: '/user/profile',
        name: 'Profile',
        component: Profile,
        meta: {requireLogin: true}
      },
      {
        path: '/user/changepassword',
        name: "Change Password",
        component: ChangePassword,
        meta: {requireLogin: true}
      },
      {
        path: '/user/forgetPassword',
        name: "Forget Password",
        component: ForgetPassword,
        meta: {requireNotLogin: true}
      },
      {
        path: '/user/resetPassword/:id',
        name: "Reset Password",
        component: ResetPassword,
        meta: {requireNotLogin: true}
      },
      {
        path: '/cart',
        name: 'Cart',
        component: Cart,
        meta: {requireLogin: true}
      },
      {
        path: '/order',
        name: 'Create Order',
        component: CreateOrder,
        props: true,
        meta: {requireLogin: true}
      },
      {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {requireLogin: true}
      },
      {
        path: '/payment/success/:id',
        name: 'Payment Success',
        component: PaymentSuccess,
        meta: {requireLogin: true, }
      }, ///payment/cancel
      {
        path: '/payment/cancel/:id',
        name: 'Payment Canceled',
        component: PaymentCancel,
        meta: {requireLogin: true, }
      }, 
      {
        path: '/receipt/:id',
        name: "ViewReceipt",
        component: Receipt,
      }
]