import axios from "axios";
import Cookie from '../utility/cookie'
import UserModel from '../store/stateModel/user.state.model'
import Auth from "./auth.service";

export default class User {
    constructor(vue = null) {
        this.user = UserModel;
        this.vue = vue
    }

    async login({ email, password }) {
        var result = await Auth.authenticate(email.trim(), password.trim())
        if (! await Auth.isResultContainError(result)) {
            this.user = result;
            Cookie.setCookie("user", JSON.stringify(this.user), 604800)
        }
        return result;
    }

    async signup({ username, email, password, conpassword }) {
        var result = await Auth.signup(username.trim(), email.trim(), password, conpassword);
        return result;
    }

    async update(value) {
        const newData = {
            preference: value.preference
        }
        const result = await axios.put(`${process.env.VUE_APP_ROOT_API}/auth/update`, newData);
        return result;
    }

    async changePassword(old, newPassword, confirm) {
        const result = await axios.put(`${process.env.VUE_APP_ROOT_API}/auth/changePassword`, {
            currentPassword: old,
            newPassword: newPassword,
            conPassword: confirm
        });
        return result;

    }

    async resetPassword(id, recoveryCode, newPassword, confirm) {
        const result = await axios.put(`${process.env.VUE_APP_ROOT_API}/auth/resetPassword/${id}`, {
            recoveryCode: recoveryCode,
            newPassword: newPassword,
            conPassword: confirm
        });
        return result;
    }

    async forgetPassword(email) {
        const result = await axios.post(`${process.env.VUE_APP_ROOT_API}/auth/forgetPassword`, {
            email
        });
        return result;
    }
    async logout() {
        Cookie.deleteCookie("user");
    }

    async getCachedUserInfo() {
        if (Cookie.isset("user")) {
            return (JSON.parse(Cookie.findCookie("user")))
        }
        return this.getLatestUserInfo();
    }

    async getLatestUserInfo() {
        var result = await Auth.getUser();
        if (await Auth.isResultContainError(result)) {
            return false;
        }
        return result;
    }

}