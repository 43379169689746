import User from "../../service/user.service";
import Cookie from "../../utility/cookie"
import UserModel from '../stateModel/user.state.model'
import router from "../../router"



var AuthenticatedUser = JSON.parse(JSON.stringify(UserModel));
if (Cookie.isset("user")) {
    try {
        AuthenticatedUser = JSON.parse(Cookie.findCookie("user"));
    } catch (error) {
        Cookie.deleteCookie("user");
    }
}



function helper_clearUserCookie(e){
    Cookie.deleteCookie("user");
    // e.AuthenticatedUser = JSON.parse(JSON.stringify(UserModel));
}


const AuthModule = {
    namespaced: true,
    state: {isAuthenticated : false,AuthenticatedUser},
    getters: {
        getUser(state) {
            return state;
        }
    },
    mutations: {
        clearUserCookie(state){
            state.isAuthenticated = false
            state.AuthenticatedUser = false;
            helper_clearUserCookie(this)
        },
        onLoginFail(state){
            state.isAuthenticated = false
            state.AuthenticatedUser = false;
            return helper_clearUserCookie(this)
        },
        onLogout(state){
            state.isAuthenticated = false
            state.AuthenticatedUser = false;
            return helper_clearUserCookie(this)
        },
        onLoginSuccess(state, user) {
            state.isAuthenticated = true
            state.AuthenticatedUser = JSON.parse(JSON.stringify(user)); 
        }

    },
    actions: {
        async login({commit, dispatch, state}, {email, password }) {
            if (!email || !password) {
                commit("clearUserCookie")
            }

            const loginUser = new User(this._vm);
            var result = await loginUser.login({email, password})
            if (result.error) {
                return false;
            }
            
            commit("UserModule/updateData", result, {root: true})
            commit("onLoginSuccess", result)
            router.push({name: "Home"})
            return true;
        },
        async logout({commit,dispatch}) {
            const LoggedUser = new User();
            LoggedUser.logout();
            commit("onLogout")
            dispatch("UserModule/logout", null, {root: true})
        }
    },
}

export default AuthModule;