import axios from "axios";
import Cookie from '../utility/cookie'
import VueApp from '../main';

if (Cookie.isset("user")) {
    try {
        const {token} = JSON.parse(Cookie.findCookie("user"));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
        Cookie.deleteCookie("user");
    }
}
   
  axios.interceptors.response.use(function (response) {

    if ([200,201, 202, 203, 204, 205, 206, 207, 208,226].indexOf(response.status) != -1) {
        return {error: false,content: response.data}
    }
  }, function ({response}) {
    if (!response) {
        VueApp.$store.dispatch("ModalModule/error", {
            title: "Connection Failed",
            content: "We had trouble conecting you to the server, please try again later",
            buttonContent: "OK",
        });
        Cookie.deleteCookie("user");
        VueApp.$store.dispatch("AuthModule/logout", {root: true})
        VueApp.$router.push("/")
        return;
    }
    if ([400].indexOf(response.status) == -1) {
        VueApp.$store.dispatch("ModalModule/error", {
            title: "Error",
            content: response.data.message,
            buttonContent: "OK",
        });
    }

    if (response.status === 401) {
        Cookie.deleteCookie("user");
        VueApp.$store.dispatch("AuthModule/logout", {root: true})
        VueApp.$router.push("/login")
    }
    return Promise.reject({error: true,content: response.data});
  });

export default axios;

