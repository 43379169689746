<template>
  <div class="text-center">
    <v-dialog :value="modal.isShown" persistent width="500">
      <v-card>
        <v-card-title :class="'headline ' +  modal.bgColor + ' lighten-2 mb-5'">
            {{modal.title}}
        </v-card-title>

        <v-card-text>
            {{modal.content}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="modal.buttonColor" text @click="close()">{{modal.buttonContent}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: "Modal",
    methods: {
        close: function () {this.$store.dispatch('ModalModule/hide')}
    },
    computed: {
        modal : function(){return this.$store.getters["ModalModule/getModal"]}
    }
};
</script>

<style>
</style>