<template>
  <v-container v-if="cart" style="width: 75%" fluid>
    <v-btn @click="backToCart" color="primary">Go Back</v-btn>
    <v-container class="border bg-white my-4" fluid>
      <h3>
        Prepare at {{ orderDate }}
        <v-chip color="primary"
          >Batch: {{ batch.name }} - {{ getBatchHour() }}</v-chip
        >
      </h3>
    </v-container>
    <v-container class="border bg-white my-4" fluid>
      <h3>Cart Item</h3>
      <v-list three-line>
        <template v-for="(cartItem, index) in cart.dishs">
          <CartListItem :cartItem="cartItem" :key="cartItem._id">
          </CartListItem>
          <v-divider
            class="m-0"
            :key="index"
            v-if="index < cart.dishs.length - 1"
          ></v-divider>
        </template>
      </v-list>
    </v-container>

    <v-container class="border bg-white my-4" fluid>
      <h3>Payment Information</h3>
      <p>Total Price Of Dish : {{ cart.total }} MYR</p>
      <p >
        Discounts : Calculated On Payment
      </p>
      <p>Final Price : {{ cart.total }} MYR</p>
      <v-col class="text-right">
        <v-btn
          large
          color="orange"
          :disabled="loading"
          class="text-right"
          @click="pay"
          >Pay</v-btn
        >
      </v-col>
    </v-container>
  </v-container>
</template>

<script>
import Order from "../../service/order.service";
import CartListItem from "../user/partial/CartListItem";
import Batch from "../../service/batch.service";
export default {
  data: function () {
    return {
      cart: null,
      orderDate: null,
      batch: null,
      loading: false,
    };
  },
  components: {
    CartListItem,
  },
  methods: {
    backToCart: function () {
      this.$router.push({
        name: "Cart",
      });
    },
    async loadBatch() {
      const batch = new Batch();
      const result = await batch.findBatch(this.$route.query.batch);
      this.batch = result.content;
    },
    getBatchHour() {
      return Batch.hourFormat(this.batch.hour);
    },
    async pay() {
      this.loading = true;
      try {
        const order = new Order();
        await order.init();
        await order.getPaymentSessions(this.orderDate, this.batch._id);
      } catch (error) {
        this.loading = false;
      }
    },
  },
  mounted: async function () {
    await this.$store.dispatch("CartModule/getCart");
    this.cart = await this.$store.getters["CartModule/getCart"];
    if (this.$route.query == undefined) {
      this.$router.push({ name: "Cart" });
      return;
    }
    if (!this.$route.query.orderDate) {
      alert("Please choose a preparation date")
      this.$router.push({ name: "Cart" });
      return;
    }
    if (!this.$route.query.batch) {
      alert("Please choose a preparation batch")
      this.$router.push({ name: "Cart" });
      return;
    }
  
    this.orderDate = this.$route.query.orderDate;
    this.loadBatch();
    this.batch = this.$route.query.batch;
  },
};
</script>

<style>
</style>