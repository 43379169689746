<template>
  <div class="m-auto pt-5" v-if="promotion">
    <h1 class="text-center">Promotion</h1>
    <v-container>
      <v-btn
        color="primary"
        class="mb-3"
        @click="
          $router.push({
            name: 'Home',
          })
        "
        >Back</v-btn
      >
      <v-carousel
        cycle
        height="240"
        class="pb-3 mb-5"
        interval="10000"
        :show-arrows="false"
        hide-delimiters
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item class="fill-height bottom-gradient">
          <v-img
            :src="require('@/assets/placeholder.png')"
            style="
              position: absolute;
              width: 100%;
              height: 100%;
              filter: blur(6px);
              -webkit-filter: blur(6px);
              -moz-filter: blur(6px);
            "
          >
          </v-img>
          <v-overlay :absolute="true" value="true">
            <h1 class="text-center">{{ promotion.name }}</h1>
            <h3 v-if="promotion.coupon" class="mt-5 text-center">
              Applicable Coupon :
              <v-chip label big class="black--text" color="white"
                >#{{ promotion.coupon.code }}</v-chip
              >
            </h3>
          </v-overlay>
        </v-carousel-item>
      </v-carousel>
      <ListDish
        style="max-width: 70%"
        v-if="!loading"
        v-bind:items="promotion.dishs"
      ></ListDish>
      <ListDishLoader
        style="max-width: 70%"
        v-else
        v-bind:items="promotion.dishs"
      ></ListDishLoader>
    </v-container>
  </div>
</template>

<script>
import Promotion from "../../../service/promotion.service";
import ListDish from "../../dishes/partial/ListDish.vue";
import ListDishLoader from "../../dishes/partial/ListDishLoader.vue";
export default {
  name: "PromotionShowcaseDetail",
  data: function () {
    return {
      promotion: null,
      loading: false,
    };
  },
  components: {
    ListDish,
    ListDishLoader,
  },
  methods: {
    loadPromotion: async function () {
      const id = this.$route.params.id;
      const promotion = new Promotion();
      this.loading = true
      try {
        const { content } = await promotion.findPromotionById(id);
        this.promotion = content;
      } catch (error) {
        this.$router.push({name: "Home"})  
      }
      this.loading = false
    },
  },
  mounted: function () {
    this.loadPromotion();
  },
};
</script>

