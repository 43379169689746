<template>
  <v-container>
    <v-card class="mx-auto my-3 p-3">
      <h1 class="">{{ currentViewingDate }}'s Order ({{ counter.numberOfNotCompletedOrderToday }})</h1>
      <v-container class="" :class="$store.state.MiscModule.isMobile? '' : 'd-flex justify-content-between'">
        <v-btn-toggle class="mt-2" :style="$store.state.MiscModule.isMobile? 'flex-direction: column; width:100%' : ''" v-model="onGoing" mandatory>
          <v-btn color="primary">
            Ongoing ({{ counter.numberOfNotCompletedOrderToday }})
          </v-btn>
          <v-btn color="primary">
            Completed ({{ counter.numberOfCompletedOrderToday }})</v-btn
          >
        </v-btn-toggle>

        <div class="d-flex mt-2">
          <v-btn color="primary" @click="setYesterday()">Yesterday</v-btn>

          <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="currentViewingDate"
                label="Please select an date"
                :persistent-hint="true"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentViewingDate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-btn color="primary" @click="setTomorrow()">Next Day</v-btn>
        </div>
      </v-container>

      <v-container>
        <v-btn-toggle :style="$store.state.MiscModule.isMobile? 'flex-direction: column; width:100%' : ''"  v-model="batchIndex" mandatory>
          <v-btn color="primary" v-for="batch in batches" :key="batch._id">
            {{ batch.name }} - {{ batchHour(batch.hour) }} ({{
              getNumberOfOrderOfBatch(batch._id)
            }})
          </v-btn>
        </v-btn-toggle>
      </v-container>

      <v-container class="border">
        <v-list two-line>
          <template v-for="(order, index) in viewAbleOrder">
            <v-list-item :key="order._id">
              <template>
                <v-list-item-content>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <p class="mr-3">
                        Order : <code>#{{ order._id }}</code>
                      </p>
                      <p class="mr-3">Name : {{ order.owner.username }}</p>
                      <v-chip color="primary" small
                        >Batch :
                        {{ batchHour(order.preparation.batch.hour) }}</v-chip
                      >
                    </div>
                    <div class="">
                      <v-btn color="success" :disabled="!checkIfAllDishIsCompleted(order._id)" v-if="!order.isCompleted" @click="setOrderAsComplete(order._id)">Completed</v-btn>
                    <v-btn color="pink" class="white--text" @click="print(order._id)">Print Receipt</v-btn>
                    </div>
                  </div>

                  <v-list dense>
                    <v-list-item
                      v-for="(preparationItem, index) in order.orderPreparationState.preparationItem"
                      :key="preparationItem._id"
                    >
                      <v-list-item-content class="d-flex">
                        <v-checkbox
                          v-if="!order.isCompleted"
                          class="my-0 py-0"
                          v-model="preparationItem.complete"
                          :label="`${preparationItem.dish.title}${generateLabelPreparationItem(preparationItem)}`"
                        >
                        </v-checkbox>
                        <label v-else for="">{{preparationItem.dish.title + " " + generateLabelPreparationItem(preparationItem)}}</label>

                        <v-divider v-if="index != order.orderPreparationState.preparationItem.length - 1"></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < batches.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import Batch from "../../../service/batch.service";
import Worker from "../../../service/worker.service";
import axios from "../../../helper/axiosWithHeader"
export default {
  name: "WorkerOrder",
  data: function () {
    return {
      orders: [],
      viewAbleOrder: [],
      batches: [],
      datepicker: null,
      onGoing: 0,
      batchIndex: 0,

      counter: {
        numberOfCompletedOrderToday: 0,
        numberOfNotCompletedOrderToday: 0,
      },
      currentViewingDate: moment().format("YYYY-MM-DD"),
    };
  },
  methods: {
    async init(){
      var batch = new Batch();
      var result = await batch.getBatches();
      this.batches = result.content;
      var worker = new Worker();
      var result = await worker.getOrders(this.currentViewingDate);
      this.orders = result.content;
      this.setCountForCompletedAndNotCompletedOrder();
      this.setViewable()
    },
    setTomorrow() {
      this.currentViewingDate = moment(this.currentViewingDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    setYesterday() {
      if (moment() > moment(this.currentViewingDate).add(0, "days")) {
        return;
      }
      this.currentViewingDate = moment(this.currentViewingDate)
        .add(-1, "days")
        .format("YYYY-MM-DD");
    },
    batchHour(hour) {
      return Batch.hourFormat(hour);
    },
    setViewable() {
      var isOngoing = this.onGoing == 0;
      var self = this;
      this.viewAbleOrder = this.orders.filter((order) => {
        var batch = self.batches[self.batchIndex];
        console.log({ batches: self.batches, batchIndex: self.batchIndex });
        return (
          order.isCompleted != isOngoing &&
          order.preparation.batch._id == batch._id
        );
      });
    },
    setCountForCompletedAndNotCompletedOrder: function () {
      var completedOrder = this.orders.filter(
        (order) => order.isCompleted == true
      );
      this.counter.numberOfNotCompletedOrderToday =
        this.orders.length - completedOrder.length;
      var notCompletedOrder = this.orders.filter(
        (order) => order.isCompleted == false
      );
      this.counter.numberOfCompletedOrderToday =
        this.orders.length - notCompletedOrder.length;
    },
    getNumberOfOrderOfBatch: function (batchId) {
      var isOngoing = this.onGoing == 0;
      return this.orders.filter(
        (order) =>
          order.isCompleted != isOngoing &&
          order.preparation.batch._id == batchId
      ).length;
    },
    checkIfAllDishIsCompleted: function (orderId) {
      const order = this.orders.find(order => order._id == orderId);
      const allFilled = order.orderPreparationState.preparationItem.every(preparationItem => preparationItem.complete == true)
      return allFilled;
    },
    generateLabelPreparationItem: function (preparationItem) {
      var label = [];
      label = preparationItem.addons.map(addonItem => `${addonItem.addon.name} - ${addonItem.price} MYR`)
      console.log({preparationItem})
      if (preparationItem.remarks) {
        label.push(`Notes : ${preparationItem.remarks}`)
      }
      if (label.length == 0) {
        return "";
      }
      return " - " + label.join(", ");
    },
    print: async function(id) {
      var routeData = await this.$router.resolve({name: 'ViewReceipt', params: {id : id}});
      var newWindow = window.open(routeData.href + "?print=1", '_blank');
    },
    async setOrderAsComplete(id){
      if (!confirm("Are you sure that all items are prepared? you can not change the status back once it's set")) {
        return;
      }
      var worker = new Worker();
      var completed = await worker.setOrderAsComplete(id);
      await this.print(id);
      this.init();

    }
  },
  mounted: async function () {
          this.init();
  },
  computed: {
    numberOfCompletedAndNotCompletedOrderToday: function () {
      this.setCountForCompletedAndNotCompletedOrder();
    },
    watchOngoingAndBatch: function(){ 
      return `${this.onGoing}|${this.batchIndex}`;
    }
  },
  watch: {
    watchOngoingAndBatch: {
      immediate: true,
      handler() {
        this.setViewable();
      },
    },
    currentViewingDate: async function(){
        var worker = new Worker();
        var result = await worker.getOrders(this.currentViewingDate);
        this.orders = result.content;
        this.setCountForCompletedAndNotCompletedOrder();
        this.setViewable();
    }
      

  },
};
</script>

<style>
</style>