<template>
  <v-container
    :class="($store.state.MiscModule.isMobile ? '' : 'w-50') + ' mt-4'"
  >
    <v-card class="mx-auto my-3 p-3">
      <h1>Coupon</h1>
      <v-text-field
        name="Coupon Code"
        label="Coupon Code"
        hint="Please enter the coupon code"
        prepend-icon="mdi-ticket-confirmation"
        v-model="couponCode"
        :error-messages="couponData.code.error"
      ></v-text-field>

      <v-text-field
        name="Coupon Name"
        label="Coupon Name"
        hint="Please enter the coupon code"
        v-model="couponData.name.content"
        :error-messages="couponData.name.error"
        append-icon="mdi-voucher"
      ></v-text-field>

      <v-select
        :items="['once', 'forever']"
        hint="How many time can user redeem ?"
        persistent-hint
        label="Redeemable Times"
        v-model="couponData.duration.content"
      ></v-select>

      <v-layout colum class="my-3">
        <v-flex>
          <v-select
            :items="['percent', 'amount']"
            v-model="couponData.type.content"
            hint="What kind of coupon is this ?"
            persistent-hint
            label="Redeemable Times"
          ></v-select>
        </v-flex>
        <v-container class="w-10">
          <v-text-field
            v-if="couponData.type.content != 'percent'"
            name="Coupon Name"
            label="Coupon Name"
            hint="Please enter the coupon code"
            append-icon="mdi-voucher"
            v-model="couponData.value.content"
          ></v-text-field>
          <v-slider
            v-else
            v-model="couponData.value.content"
            class="mt-3"
            label="Discount percent"
            thumb-size="34"
            thumb-color="primary"
            thumb-label="always"
          >
            <template v-slot:thumb-label="{ value }" class="py-2 mb-0">
              {{ value }} %
            </template>
          </v-slider>
        </v-container>
      </v-layout>

      <p>How many times can the coupon be redeemed?</p>
      <div class="d-flex justify-content-between">
        <v-checkbox
          class="mr-3"
          label="Unlimited Use"
          v-model="redeemed_option"
          input-value="true"
          value
        ></v-checkbox>
        <v-text-field
          v-if="!redeemed_option"
          name="Coupon Redemptions (Total)"
          label="Coupon Redemptions (Total)"
          hint="How many times can the coupon be redeemed ?"
          persistent-hint
          min="1"
          type="number"
          v-model="couponData.redemptions.content"
          append-icon="mdi-voucher"
        >
        </v-text-field>
      </div>
      <p>How long does the coupon last? (Expire {{momentHuman(couponData.redeem_by.content)}})</p>
      <div class="d-flex justify-content-start">
        <div>
          <v-date-picker 
          class="mr-2 my-1 border"
          v-model="couponData.redeem_by.content"
          :min="today"
          :max="maxDay"
          landscape
          full-width
          ></v-date-picker>
        </div>
      </div>


      <div class="d-flex justify-content-end">
          <v-btn color="primary" @click="create">Create Coupon</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import moment, { duration } from "moment"
import InputValidator from "../../../../utility/inputValidator"
import Coupon from "../../../../service/coupon.service"
export default {
  name: "CreateCoupon",
  data: function () {
    return {
      couponData: {
        code: {
            content: "",
            error: []
        },
        duration: {
            content: "once",
            error: []
        },
        name: {
            content: "",
            error: []
        },
        type: {
            content: "percent",
            error: []
        },
        value: {
            content: 1,
            error: []
        },
        redemptions: {
            content: null,
            error: []
        },
        redeem_by: {
            content: "",
            error: []
        },
        valid: {
            content: true,
            error: []
        },
      },
      redeemed_option: true,
      today: "",
      maxDay: ""
    };
  },
  methods: {
      momentHuman: function(date) {
        return moment(date).endOf("day").fromNow()
      },
      create: async function(){
          const newData = {
            name: this.couponData.name.content,
            code: this.couponData.code.content,
            duration: this.couponData.duration.content,
            type:this.couponData.type.content.toLocaleLowerCase(),
            value:this.couponData.value.content,
            max_redemptions: this.couponData.redemptions.content,
            redeem_by: this.couponData.redeem_by.content,
            valid: true
          }

          InputValidator.clearError(this.couponData);
          try {
            const {name, code,duration, type, value, max_redemptions, redeem_by, valid} = newData;
            
            const coupon = new Coupon();
            const result = await coupon.insertCoupon(name, code,duration, type, value, max_redemptions, redeem_by, valid)
            await this.$store.dispatch("ModalModule/success", {
                title: `Coupon added`,
                content: "The coupon has been added",
                buttonContent: "Ok",
            });
            this.$router.push({name: "List Coupon"})
          } catch ({content}) {
            InputValidator.mergeError(this.couponData, content);
            this.$nextTick(() => {
                var targetDiv = document.querySelector(".error--text").offsetTop;
                window.scrollTo({ top: targetDiv, behavior: 'smooth'});
            })
          }
        


      }

  },
  mounted: function(){
    this.today = moment().format("YYYY-MM-DD")
    this.maxDay = moment().add(5, "year").format("YYYY-MM-DD")
    this.couponData.redeem_by.content = this.today
  },
  watch: {
    "couponData.type": function () {
      this.couponData.value.content = "";
    },
    redeemed_option: function () {
      if (!this.redeemed_option) {
        this.couponData.redemptions.content = 1;
        return;
      }
      this.couponData.redemptions.content = null;
    },
  },
  computed: {
    couponCode: {
      get: function(){
        return this.couponData.code.content;
      },
      set: function(coupon){
        this.couponData.code.content = coupon.toUpperCase().trim().replace(/\s+/g, '')
      },
    }
  }
};
</script>

<style>
</style>