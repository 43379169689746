<template>
  <v-container>
    <v-card
      class="mx-auto mt-5 p-3"
      :style="$store.state.MiscModule.isMobile ? '' : 'width:70%'"
    >
      <div v-if="!isLoading">
        <h1 class="mx-10 text-center display-4 p-10">Payment canceled</h1>
        <p class="text-center">
          Your payment has been canceled, your order will be removed in 2 hours
        </p>
        <Receipt :order="order"></Receipt>
        <div class="d-flex justify-content-center">
          <v-btn @click="$router.push({ name: 'Home' })" large color="primary"
            >Go Home</v-btn
          >
        </div>
      </div>
      <div class="d-flex justify-content-center" v-else>
              <v-progress-circular
              class="mx-auto"
      :size="100"
      color="primary"
      indeterminate
    ></v-progress-circular>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Order from "../../service/order.service";
import Receipt from "./Receipt";
export default {
  name: "PaymentCancel",
  data: function () {
    return {
      order: null,
      isLoading: true,
    };
  },
  components: {
    Receipt,
  },
  mounted: async function () {
    const order = new Order();
    if (this.$route.params.id) {
      const { content } = await order.getOrder(this.$route.params.id);
      this.isLoading = false;
      if (content.isPaid) {
           this.$router.push({name: "ViewReceipt", params: { id : content._id}})
      }
      this.order = content;
    }
  },
};
</script>

<style>
</style>