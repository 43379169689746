import axios from '../helper/axiosWithHeader'

export default class Cart {
    async getCart(){
        return await axios.get(`${process.env.VUE_APP_ROOT_API}/cart?timestamp=${new Date().getTime()}`);
    }

    async clearCart(){
        return await axios.remove(`${process.env.VUE_APP_ROOT_API}/cart`);
    }

    async addDish(id, addonSelection, quantity, remarks) {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/cart/dish/${id}`, {
            addonSelection,
            quantity, 
            remarks
        });
    }

    async updateDish(id, addonSelection, quantity, remarks) {
        return await axios.patch(`${process.env.VUE_APP_ROOT_API}/cart/dish/${id}`, {
            addonSelection,
            quantity, 
            remarks
        });
    }

    async removeDish(id) {
        return await axios.delete(`${process.env.VUE_APP_ROOT_API}/cart/dish/${id}`);
    }
}