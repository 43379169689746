<template>
    <v-container>
    <div class="row mx-auto">
      <div class="col-lg-3 col-sm-6" v-for="item in loadCount" :key="item">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
    name: "ListDishLoader",
    data: function () {
        return {
            loadCount: this.items
        }
    },
    props: ["items"]
}
</script>

<style>

</style>