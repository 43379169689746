<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-container class="dark">
          <h1 class="text-center display-3">Sign up</h1>
          <v-divider class="py-2"></v-divider>
          <v-form ref="form">
            <v-text-field
              prepend-icon="mdi-account"
              v-model="signupDetail.username.content"
              label="Username"
              :error-messages="signupDetail.username.error"
              :error-count="signupDetail.username.error.length"
              required
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-email"
              v-model="signupDetail.email.content"
              label="Email"
              :error-messages="signupDetail.email.error"
              :error-count="signupDetail.email.error.length"
              required
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-form-textbox-password"
              v-model="signupDetail.password.content"
              label="Password"
              :type="signupDetail.password.show ? 'text' : 'password'"
              :error-messages="signupDetail.password.error"
              :error-count="signupDetail.password.error.length"
              required
            >
              <v-icon slot="append" @click="signupDetail.password.show = !signupDetail.password.show">{{
                !signupDetail.password.show ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-text-field>

            <v-text-field
              prepend-icon="mdi-form-textbox-password"
              v-model="signupDetail.conpassword.content"
              label="Confrim password"
              :type="signupDetail.conpassword.show ? 'text' : 'password'"
              :error-messages="signupDetail.conpassword.error"
              :error-count="signupDetail.conpassword.error.length"
              required
            >
              <v-icon
                slot="append"
                @click="signupDetail.conpassword.show = !signupDetail.conpassword.show"
                >{{ !signupDetail.conpassword.show ? "mdi-eye" : "mdi-eye-off" }}</v-icon
              >
            </v-text-field>
            <router-link to="/login">
              Already have an account ? Login here
            </router-link>

            <v-btn block color="primary" class="mt-2 mr-4" @click="signup">
              <v-icon class="mr-2">mdi-account-plus</v-icon>
              Sign Up
            </v-btn>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template> 

<script>
import User from "../../service/user.service"
import InputValidator from '../../utility/inputValidator'
export default {
  name: "Register",
  data() {
    return {
      signupDetail: {
              username: {
        content: "",
        error: [],
      },
      email: {
        content: "",
        error: [],
      },
      password: {
        content: "",
        error: [],
        show: false,
      },
      conpassword: {
        content: "",
        error: [],
        show: false,
      },
      }
    };
  },
  methods: {
    signup: async function () {
      InputValidator.clearError(this.signupDetail)
      const newUser = new User();
      try {
        
        var result = await newUser.signup({
          username: this.signupDetail.username.content,
        email: this.signupDetail.email.content,
        password: this.signupDetail.password.content,
        conpassword: this.signupDetail.conpassword.content,
      });
        this.$store.dispatch("ModalModule/success",{
          title: "Sign up success!!",
          content: "Your account has been successfully created, you may now login",
          buttonContent: "Ok"
        })
        this.$router.push("/login")
      } catch ({content}) {
        InputValidator.mergeError(this.signupDetail, content)
      }
    },
  },
};
</script>