<template>
  <v-dialog v-model="addonModal.state" width="500">
    <v-card>
      <v-card-text>
        <v-card-title v-if="!update"> Add Addon </v-card-title>
        <v-card-title v-if="update"> Edit Addon </v-card-title>
        <v-container>
          <v-text-field
            label="Addon Name"
            v-model="addonModal.name.content"
            :error-messages="addonModal.name.error"
            :error-count="addonModal.name.error.length"
          ></v-text-field>

          <div class="">

                      <v-text-field
            label="Addon Price"
            type="number"
            v-model="addonModal.price.content"
            :error-messages="addonModal.price.error"
            :error-count="addonModal.price.error.length"
          ></v-text-field>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="addonModal.state = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="!update" text @click="addAddon()">
          Add Addon
        </v-btn>
        <v-btn color="primary" v-if="update" text @click="updateAddon()">
          Edit Addon
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputValidator from "../../../../../utility/inputValidator";
import Addon from "../../../../../service/addon.service";
export default {
  name: "AddonModal",
  props: ["addonModal", "update"],
  methods: {
    addAddon: async function () {
      InputValidator.clearError(this.addonModal);

      const addon = new Addon();
      try {
        const { name, price } = this.addonModal;
        const result = await addon.createAddon(name.content, price.content);
        name.content = "";
        price.content = 0;

        this.$emit("loadAddon", "");
        this.addonModal.state = false;
      } catch (error) {
        this.addonModal.state = true;

        InputValidator.mergeError(this.addonModal, error.content);
        console.log(this.addonModal);
      }
    },
    updateAddon: async function () {
      InputValidator.clearError(this.addonModal);

      const addon = new Addon();
      try {
        const { name, price } = this.addonModal;
        const result = await addon.editAddon(this.addonModal.addon._id, name.content, price.content);
        name.content = "";
        price.content = 0;

        this.$emit("loadAddon", "");
        this.addonModal.state = false;
      } catch (error) {
        this.addonModal.state = true;
        console.log(error)
        InputValidator.mergeError(this.addonModal, error.content);
        console.log(this.addonModal);
      }
    },

    priceFormat: function (price) {
      if (price < 12) {
        return `${price}:00am`;
      } else if (price == 12) {
        return `${price}:00pm`;
      }
      return `${price - 12}:00pm`;
    },
  },
};
</script>

<style>
</style>