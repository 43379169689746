import axios from '../helper/axiosWithHeader'


export default class Dish {



    async getDish(id) {
        const dishes = await axios.get(`${process.env.VUE_APP_ROOT_API}/dish/id/${id}`);
        return dishes;
    }

    async addDish(input) {
        const dishes = await axios.post(`${process.env.VUE_APP_ROOT_API}/dish`, input);
        return dishes;
    }

    async updateDish(id, input) {
        const dishes = await axios.put(`${process.env.VUE_APP_ROOT_API}/dish/${id}`, input);
        return dishes;
    }

    async deleteDish(id) {
        const dishes = await axios.delete(`${process.env.VUE_APP_ROOT_API}/dish/${id}`);
        return dishes;
    }

    async toggleDishList(id , state) {
        const dishes = await axios.put(`${process.env.VUE_APP_ROOT_API}/dish/${id}/toggle/${state}`);
        return dishes;
    }

    async searchDish(title, tags, priceRange, page, limit) {

        var searchTerm = {}
        if (title) searchTerm['dishName'] = title
        if (tags) searchTerm['containTags'] = tags
        if (priceRange) {
            if (priceRange.min && priceRange.min != null || priceRange.max && priceRange.max != null ) {
                searchTerm['priceRange'] = priceRange
            }
        }


        const dishes = await axios.post(`${process.env.VUE_APP_ROOT_API}/dish/search`, {
            page, limit, ...searchTerm
        });
        return dishes;

    }

    async searchAdminDish(title, tags, priceRange, page, limit, hidden = false) {

        var searchTerm = {}
        if (title) searchTerm['dishName'] = title
        if (tags) searchTerm['containTags'] = tags
        if (priceRange) {
            if (priceRange.min && priceRange.min != null || priceRange.max && priceRange.max != null ) {
                searchTerm['priceRange'] = priceRange
            }
        }
        if (hidden) searchTerm['hidden'] = hidden



        const dishes = await axios.post(`${process.env.VUE_APP_ROOT_API}/dish/admin/search`, {
            page, limit, ...searchTerm
        });
        return dishes;

    }

}