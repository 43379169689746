<template>
  <nav>
    <FullSizeNavigation
      v-bind:navigationItem="getLatestNavigation"
      v-bind:userInfo="user"
      v-bind:cart="cart"
      v-if="!$store.state.MiscModule.isMobile"
    ></FullSizeNavigation>
    <MobileNavigation
      v-bind:navigationItem="getLatestNavigation"
      v-bind:userInfo="user"
      v-bind:cart="cart"
      v-if="$store.state.MiscModule.isMobile"
    ></MobileNavigation>
  </nav>
</template>


<script >
import FullSizeNavigation from "./fullSizeNavigation/FullSizeNavigation";
import MobileNavigation from "./mobileNavigation/MobileNavigation";
export default {
  name: "Navigation",
  components: {
    FullSizeNavigation,
    MobileNavigation,
  },
  props: ["navigationItem", "userInfo"],
  methods: {
    checkIfMobile(e) {
      var viewPortWidth = e.target.self.innerWidth;

      this.$store.dispatch("MiscModule/updateMobileState", viewPortWidth)
    },
  },
  created() {
    window.addEventListener("resize", this.checkIfMobile);
    this.$store.dispatch("MiscModule/updateMobileState", window.innerWidth)
  },
  destroyed() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  computed: {
    getLatestNavigation : function(){
      return  this.$store.getters["NavigationModule/getNavigationItem"];
    }
  },
  asyncComputed: {
    user: {
      async get(){
        const result = await this.$store.getters['UserModule/getUser'];
        return result
      },
    },
    cart: {
      async get(){
        return await this.$store.getters['CartModule/getCart']
      },
      default: {dishs: []}
    }
  },
  mounted() {
    helper_setNavigation(this)

    this.$store.watch(
      (state, getters) => this.$store.getters["UserModule/getUser"],
      (n) => {
        helper_setNavigation(this)
      }
    );
  },
};

async function helper_setNavigation(vue) {
        var userObj = await vue.$store.getters["UserModule/getUser"];
        if (userObj != null) {
          vue.$store.dispatch(
            "NavigationModule/setNavigation",
            userObj.accountType
          );
        }
        else {
          vue.$store.dispatch(
            "NavigationModule/setNavigation",
            "notLogin"
          );
        }
}
</script>