<template>
  <v-container>
    <h1 class="text-center display-3">Create Worker Account</h1>
    <v-divider class="py-2"></v-divider>
    <v-form ref="form">
      <v-text-field
        prepend-icon="mdi-account"
        v-model="workerDetail.username.content"
        label="Username"
        :error-messages="workerDetail.username.error"
        :error-count="workerDetail.username.error.length"
        required
      ></v-text-field>

      <v-text-field
        prepend-icon="mdi-email"
        v-model="workerDetail.email.content"
        label="Email"
        :error-messages="workerDetail.email.error"
        :error-count="workerDetail.email.error.length"
        required
      ></v-text-field>

      <v-text-field
        prepend-icon="mdi-form-textbox-password"
        v-model="workerDetail.password.content"
        label="Password"
        disabled
        readonly
      >
      </v-text-field>

      <v-btn block color="primary" class="mt-2 mr-4" @click="createWorker">
        <v-icon class="mr-2">mdi-account-plus</v-icon>
        Create Worker
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import Admin from '../../../../service/admin.service'
import InputValidator from '../../../../utility/inputValidator';
export default {
  name: "CreateWorker",
  data: function () {
    return {
      workerDetail: {
        username: {
          content: "",
          error: [],
        },
        email: {
          content: "",
          error: [],
        },
        password: {
          content: "",
          show: true,
        },
      },
    };
  },
  methods: {
    async createWorker() {
        const admin = new Admin();
        InputValidator.clearError(this.workerDetail)
        try {
            var result = await admin.createWorker(this.workerDetail.username.content, this.workerDetail.email.content, this.workerDetail.password.content)
            this.$store.dispatch("ModalModule/success", {
                title: "Account creation success!!",
                content: `Account creation sucessful, 
                user ${this.workerDetail.username.content}, may login with ${this.workerDetail.email.content} with ${this.workerDetail.password.content} (please ask the user to change their password)
                `,
                buttonContent: "Ok"
            })
        } catch ({content}) {
            InputValidator.mergeError(this.workerDetail, content)
      }
    },
  },
};
</script>

<style>
</style>