export default class Cookie {
    static setCookie(name, value, exp = null) {
        var now = new Date();
        now.setSeconds(now.getSeconds()+ exp);
        var expirationDate = (exp)? `expires=${now.toUTCString()}`: "";
        document.cookie = `${name}=${value};${expirationDate};path=/`
    }

    static getAllCookie(){
        var cookieInPair = decodeURIComponent(document.cookie).split(";").map(e => e.split(" ").join("").split("="))
        return Object.fromEntries(cookieInPair)
    }

    static deleteCookie(name) {
        this.setCookie(name,null, (new Date()).toUTCString());
    }

    static findCookie(name){
        var allCookie = this.getAllCookie();
        if (allCookie[name] != undefined) {
            return allCookie[name];
        }
        else {
            return ""
        }
    }

    static isset(name){
        var allCookie = this.getAllCookie();
        return (allCookie[name] != undefined);
    }
}