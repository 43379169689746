import UserModule from "./module/user.store.module";
import ModalModule from "./module/modal.store.module";
import AuthModule from "./module/auth.store.module";
import NavigationModule from "./module/navigation.store.module"
import MiscModule from "./module/misc.store.module"
import CartModule from "./module/cart.store.module"

const modules =  {
    ModalModule,
    UserModule,
    AuthModule,
    NavigationModule,
    MiscModule,
    CartModule
}

  
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({modules})