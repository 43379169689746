<template>
    <v-container fluid>
      <v-card  class="pt-5 mx-auto" :style="($store.state.MiscModule.isMobile)? '' : 'max-width: 60%' " v-if="user" tile>
        <h1 class="text-center">
          Username : {{user.username}} 
          <small>({{user.accountType}})</small>
        </h1>
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab @click="tab = 2">
            Recent Order
            <v-icon>mdi-food</v-icon>
          </v-tab>

          <v-tab @click="tab = 1">
            User Settings
            <v-icon>mdi-account-cog</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <PreviousOrder :orders="orders"></PreviousOrder>

          <UserSettings></UserSettings>
        </v-tabs-items>
      </v-card>
  </v-container>
</template>
<script>
import PreviousOrder from './partial/Profile/PreviousOrder/PreviousOrder'
import UserSettings from './partial/Profile/UserSettings/UserSettings'
import Order from '../../service/order.service'
export default {
  name: "Profile",
  data() {
    return {
      tab: 2,
      orders: []
    };
  },
  components: {
    PreviousOrder, UserSettings
  },
  mounted: async function (){
    const order = new Order();
    try {
      const result = await order.userGetRelatedOrder()
      this.orders = result.content;
    } catch (error) {
      
    }
  },
  asyncComputed: {
    user: {
      async get(){
        const result = await this.$store.getters['UserModule/getUser'];
        return result
      },
      default: {
        username: "loading",
        accountType: "user"
      }
    }
  }
};
</script>

