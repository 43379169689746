


const ModalModule = {
    namespaced: true,
    state: {
        isShown: false,
        bgColor: "primary", 
        title: "",
        content: "",
        buttonColor: "primary",
        buttonContent: ""
    },
    getters: {
        getModal(state) {
            return state;
        }
    },
    mutations: {
        enableModal(state) {state.isShown = true},
        disableModal(state) {state.isShown = false},
        resetModal(state) {
            state.isShown = false;
            state.bgColor = "primary";
            state.title = "";
            state.content = "";
            state.buttonColor = "primary";
            state.buttonContent = "";
        },

        errorModal(state) {
            state.bgColor = "red";
            state.buttonColor = "red";
        },
        successModal(state) {
            state.bgColor = "green";
            state.buttonColor = "green";
        },
        notifyModal(state) {
            state.bgColor = "teal";
            state.buttonColor = "teal";
        },
        setModalTitleContentAndButtonContent(state, {title, content, buttonContent}) {
            state.title = title
            state.content = content
            state.buttonContent = buttonContent
        }
    },
    actions: {
        success({commit, dispatch}, data) {
            commit("resetModal")
            commit("setModalTitleContentAndButtonContent", data)
            commit("successModal");
            commit("enableModal")
        },
        error({commit, dispatch}, data) {
            commit("resetModal")
            commit("setModalTitleContentAndButtonContent", data)
            commit("errorModal");
            commit("enableModal")

        },
        notify({commit, dispatch}, data) {
            commit("resetModal")
            commit("setModalTitleContentAndButtonContent", data)
            commit("notifyModal");
            commit("enableModal")

        },
        hide({commit, dispatch}) {
            commit("disableModal")
        }
    },
}

export default ModalModule;