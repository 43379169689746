<template>
    <v-container class="p-5">
        <v-btn class="float-right" color="primary" @click="print()">Print</v-btn>
        <div class="mb-1">
            <h6 class="text-center">Let the cashier scan this</h6>
            <img class="d-block mx-auto" style="width: 140px; height:140px" :src="qr" alt="">
        </div>
        <h3 class="pl-3">Receipt: <code>#{{order._id}}</code></h3>
        <h3 class="pl-3">Customer Name: <code>#{{order.owner.username}}</code></h3>
        <Receipt v-if="order" :order="order"></Receipt>
    </v-container>
</template>

<script>
import Receipt from "./Receipt";
import Order from "../../service/order.service";
import QRCode from 'qrcode'

export default {
  name: "ViewReceipt",

  data: function () {
    return {
      order: null,
      qr: null
    };
  },
  methods: {
    async getOrder(id) {
      console.log(id)
      const order = new Order();
      try {
        const orderResult = await order.getOrder(id);
        this.order = orderResult.content;
        this.qr = await QRCode.toDataURL(`${this.order._id}`);
      } catch (error) {
        this.$store.dispatch("ModalModule/error", {
          title: "Could not find order",
          content: "We are unable to find the order",
          buttonContent: "Ok",
        });
      }
    },
    print(){
      window.onafterprint = function(){
        window.close();
      }
      window.print();
    }
  },
  components: {
    Receipt,
  },
  mounted: async function () {
    await this.getOrder(this.$route.params.id);
    if (this.$route.query.print) {
      this.print();
    }
  },
};
</script>

<style>
</style>