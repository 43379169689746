import axios from '../helper/axiosWithHeader'
import { loadStripe } from '@stripe/stripe-js';

export default class Order {
    constructor() {
        this.stripe = null;
    }

    async init() {
        try {
            const result = await axios.get(`${process.env.VUE_APP_ROOT_API}/order/token`)
            this.stripe = await loadStripe(result.content)
        } catch (error) {
            console.log(error)
            alert("error has occurred, cant obtain the toke from server")
        }
    }

    async getOrder(id) {
        const result = await axios.get(`${process.env.VUE_APP_ROOT_API}/order/id/${id}`)
        return result
    }

    async getPaymentSessions(date, batch, discount = "") {
        var self = this;
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/order/generateSession`, {
            orderDate: date,
            batch,
            discount
        }).then(function (response) {
            return response.content;
        }).then(function (session) {
            console.log(session)
            return self.stripe.redirectToCheckout({ sessionId: session });
        }).then(function (result) {
            if (result.error) {
                alert(result.error.message);
            }
        }).catch(function (error) {
            throw error
            console.error("Error:", error);
        });
    }

    async userGetRelatedOrder() {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/order/allOrder`)
    }

    async userGetRelatedOrderById(id) {
        return await axios.post(`${process.env.VUE_APP_ROOT_API}/order/allOrder/${id}`)
    }



}