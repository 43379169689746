import Cart from "../../service/cart.service";

const CartModule = {
    namespaced: true,
    state: {
        cart: null
    },
    getters: {
        async getCart(state) {
            return state.cart;
        }
    },
    mutations: {
        async syncCart(state, cart) {
            state.cart = cart;
        }
    },
    actions: {
        async getCart({ commit, dispatch, state }) {
            const cart = new Cart()
            commit("syncCart", (await cart.getCart()).content)
        },
        async addDishCart({ commit, dispatch, state }, {
            id, addon, quantity, remarks
        }) {
            const cart = new Cart();
            try {
               const newCart = await cart.addDish(id, addon, quantity, remarks);
               commit("syncCart", newCart.content);
               return newCart;
            } catch (error) {
                console.log(error)
                return error;
            }
        },
        async updateCart({ commit, dispatch, state}, {
            id, addon, quantity, remarks
        }) {
            const cart = new Cart();
            console.log({addon})
            try {
               const newCart = await cart.updateDish(id, addon, quantity, remarks);
               commit("syncCart", newCart.content);
               return newCart;

            } catch (error) {
                console.log(error)
                return error;
            }
        },
        async removeDishCart({ commit, dispatch, state }, id) {
            const cart = new Cart();
            const newCart = await cart.removeDish(id);
            commit("syncCart", newCart.content);
        },
        async clearCart({ commit, dispatch, state }) {
            const cart = new Cart();
            const newCart = await cart.clearCart();
            commit("syncCart", newCart.content);
        }

    },
}

export default CartModule;